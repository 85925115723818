<template>
  <div class="configFileMachine">
    <i class="el-icon-close" @click="closeDialog"></i>
    <div class="top">
      <div class="label">选择配置文件</div>
      <div class="value">
        <span class="select">已选中</span>
        <span class="name">{{
          configFileList[selectConfigFileIndex].confName
        }}</span>
      </div>
    </div>
    <div class="config">
      <div class="left">
        <div class="default">
          <div class="title">默认配置</div>
          <div
            class="value"
            v-for="(item, index) in defaultConfig"
            :key="index"
            :style="{
              border:
                selectConfigFileIndex === 0 ? '1px solid #d71518' : 'none',
            }"
            @click="selectConfig(item.id)"
          >
            <div class="name">{{ item.confName }}</div>
            <div class="resin">树脂：{{ item.resinType }}</div>
            <div class="dep">层厚：{{ item.layerThickness + "mm" }}</div>
          </div>
        </div>
        <div class="userDefine">
          <div class="title">自定义配置</div>
          <div
            class="value"
            @click="selectConfig(item.id)"
            v-for="(item, index) in userDefineConfig"
            :key="index"
            :style="{
              border:
                selectConfigFileIndex === index + 1
                  ? '1px solid #d71518'
                  : 'none',
            }"
          >
            <div class="name">{{ item.confName }}</div>
            <div class="bottom">
              <div class="left-content">
                <div class="resin">树脂：{{ item.resinType }}</div>
                <div class="dep">层厚：{{ item.layerThickness + "mm" }}</div>
              </div>
              <div class="right-del" @click="delMachine(item.id)" :class="{active: isDelConfigParmars && (selectConfigFileIndex === index + 1)}">删除</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">全部参数</div>
        <div class="type">
          <span class="label">机型</span>
          <span class="value">{{
            configFileList[selectConfigFileIndex].printerTypeName
          }}</span>
        </div>
        <div class="resin">
          <span class="label">树脂</span>
          <span class="value">{{
            configFileList[selectConfigFileIndex].resinType
          }}</span>
        </div>
        <div class="line"></div>
        <div class="set">
          <div class="top">
            <span
              class="print"
              @click="isShowPrintSet = true"
              :style="{
                borderBottom: isShowPrintSet ? '3px solid #D71518' : 'none',
                color: isShowPrintSet ? '#000' : '#5a5a5a',
              }"
              >打印设置</span
            >
            <span
              class="high"
              @click="isShowPrintSet = false"
              :style="{
                borderBottom: !isShowPrintSet ? '3px solid #D71518' : 'none',
                color: !isShowPrintSet ? '#000' : '#5a5a5a',
              }"
              >高级设置</span
            >
          </div>
          <div class="par">
            <ul class="print" v-if="isShowPrintSet">
              <li>
                <span class="name">层厚</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].layerThickness + "mm"
                }}</span>
              </li>
              <li>
                <span class="name">单层曝光时间</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].layerExposure + "s"
                }}</span>
              </li>
              <li>
                <span class="name">底层曝光时间</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].bottomExposure + "s"
                }}</span>
              </li>
              <li>
                <span class="name">曝光延迟</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].exposureDelay + "ms"
                }}</span>
              </li>
              <li>
                <span class="name">底层曝光层数</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].bottomExposureLayers +
                  "n"
                }}</span>
              </li>
              <li>
                <span class="name">底层曝光过渡层数</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex]
                    .bottomExposureTransition + "n"
                }}</span>
              </li>
              <li>
                <span class="name">抬升高度1</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].liftingHeightOne + "mm"
                }}</span>
              </li>
              <li>
                <span class="name">抬升速度1</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].liftingSpeedOne +
                  "mm/min"
                }}</span>
              </li>
              <li>
                <span class="name">抬升高度2</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].liftingHeightTwo + "mm"
                }}</span>
              </li>
              <li>
                <span class="name">抬升速度2</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].liftingSpeedTwo +
                  "mm/min"
                }}</span>
              </li>
              <li>
                <span class="name">下降速度</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].declineSpeed + "mm/min"
                }}</span>
              </li>
              <li>
                <span class="name">打印完Z轴抬升</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].zaxisLiftComplete +
                  "mm"
                }}</span>
              </li>
            </ul>
            <ul class="high" v-else>
              <li>
                <span class="name">内径补偿</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex]
                    .insideDiameterCompensation + "mm"
                }}</span>
              </li>
              <li>
                <span class="name">外径补偿</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex]
                    .outsideDiameterCompensation + "mm"
                }}</span>
              </li>
              <li>
                <span class="name">X轴缩放补偿</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].xaxisScaleCompensation +
                  "%"
                }}</span>
              </li>
              <li>
                <span class="name">Y轴缩放补偿</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].yaxisScaleCompensation +
                  "%"
                }}</span>
              </li>
              <li>
                <span class="name">Z轴缩放补偿</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].zaxisScaleCompensation +
                  "%"
                }}</span>
              </li>
              <li>
                <span class="name">抗锯齿</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].antiAliasingLevel
                }}</span>
              </li>
              <li>
                <span class="name">灰度级别</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].grayLevel
                }}</span>
              </li>
              <li>
                <span class="name">图像模糊像素</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].imageBlurPixel
                }}</span>
              </li>
              <li>
                <span class="name">自动支撑</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].isAutoSupport
                    ? "开启"
                    : "关闭"
                }}</span>
              </li>
              <li>
                <span class="name">支撑密度</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].supportDensity + "%"
                }}</span>
              </li>
              <li>
                <span class="name">添加底座</span
                ><span class="value">{{
                  configFileList[selectConfigFileIndex].isAddBase
                    ? "开启"
                    : "关闭"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button class="submit" @click="closeDialog">确定</el-button>
      <el-button class="cancel" @click="closeDialog">取消</el-button>
    </div>
    <div class="dialog" v-if="isDelConfigParmars">
      <div class="tip">
        提示
        <i class="icon-a-guanbi2 iconfont" @click="isDelConfigParmars = false"></i>
      </div>
      <div class="txt">是否删除该配置参数？删除将不可恢复</div>
      <div class="btns">
        <el-button class="confirm" @click="delConfig(deledConfigId)">确定</el-button>
        <el-button class="cancel" @click="isDelConfigParmars = false">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { deletePrinterConfiguration } from "../api/getInfo";
export default {
  name: "configFileManage",
  props: {
    configFileList: [],
    selectConfigFileIndex: {
      // default: 0,
      type: Number,
    },
  },
  data() {
    return {
      //被删除配置的id
      deledConfigId: null,
      isDelConfigParmars: false,
      isShowPrintSet: true,
      printSet: {
        layerThickness: "层厚",
        layerExposure: "单层曝光时间",
        bottomExposure: "底层曝光时间",
        exposureDelay: "曝光延迟",
        bottomExposureLayers: "底层曝光层数",
        bottomExposureTransition: 6,
        liftingHeightOne: 3,
        liftingSpeedOne: 40,
        liftingHeightTwo: 3,
        liftingSpeedTwo: 100,
        declineSpeed: 100,
        zaxisLiftComplete: 100,
      },
    };
  },
  methods: {
   async delConfig(id) {
    this.isDelConfigParmars = false
      const res = await deletePrinterConfiguration(id);
      console.log("resDel", res);
      if (res.code === 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
      } else {
        this.$message.error("删除失败");
      }
      this.$emit("closeConfigFileMachineDialog", true);
    },
    closeDialog() {
      this.$emit("closeConfigFileMachineDialog");
    },
    selectConfig(id) {
      let index = this.configFileList.findIndex((item) => id === item.id);
      console.log("index", index);
      this.$emit("toggleConfIndex", index);
    },
    delMachine(id) {
      this.isDelConfigParmars = true
      this.deledConfigId = id
    },
  },
  mounted() {
    console.log("configList", this.configFileList);
  },
  computed: {
    defaultConfig() {
      return this.configFileList.filter((val) => val.isDefault);
    },
    userDefineConfig() {
      console.log(
        "userDefineConfig",
        this.configFileList.filter((val) => !val.isDefault)
      );
      return this.configFileList.filter((val) => !val.isDefault);
    },
  },
};
</script>

<style lang="less" scoped>
.configFileMachine {
  // position: relative;
}
</style>