<template>
  <div class="set-printer">
    <div class="set-printer-dialog">
      <el-dialog
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
      >
        <div class="saveConfigDialog" v-if="isShowSaveConfiDialog">
          <i class="el-icon-close" @click="isShowSaveConfiDialog = false"></i>
          <div class="title">参数配置命名</div>
          <el-input v-model="newConfName"></el-input>
          <el-button type="primary" @click="saveNewConfig">确定</el-button>
        </div>
        <i class="el-icon-close" @click="closeDialog"></i>
        <div class="top">
          <div class="title">
            <i class="iconfont icon-a-Group14154"></i>
            <div class="txt">
              <p class="select">3D打印机选择</p>
              <p class="newAdd">选择你的3D打印机或新增</p>
            </div>
          </div>
          <!--  <el-input
            v-model="searchMachineName"
            prefix-icon="el-icon-search"
            placeholder="搜索你想要的机型"
            @input="searchMachinesFunc"
            :debounce="5000"
          ></el-input> -->
          <div
            class="manageMachine"
            :style="{
              backgroundColor: isDelMachine ? '#d71518' : '#fff',
              color: isDelMachine ? '#fff' : '#d71518',
            }"
            @click="isDelMachine = !isDelMachine"
          >
            <i class="iconfont icon-guanli"></i>
            <span class="txt">管理机型</span>
          </div>
        </div>

        <div class="banner">
          <ul>
            <li
              v-for="(item, index) in machines"
              :key="item.id"
              @click="selectMachine(index)"
            >
              <div class="pic">
                <i
                  class="el-icon-check"
                  v-if="selectedMachineIndex === index && !isDelMachine"
                ></i>
                <i
                  class="icon-bianji iconfont"
                  v-if="isDelMachine"
                  @click="manageMachine(index)"
                ></i>
                <img :src="item.picFileUrl" alt="" />
              </div>
              <div
                class="name"
                :style="{
                  background:
                    selectedMachineIndex === index
                      ? 'linear-gradient(90deg, #F14C41 -4.38%, #FF8F88 100%)'
                      : '#A0A0A0',
                }"
              >
                {{ item.name }}
              </div>
            </li>
            <li @click="isShowUserDefinedMachine = true">
              <div class="addMachine">
                <i class="el-icon-plus"></i>
                <span class="add">添加自定机型</span>
              </div>
            </li>
          </ul>
        </div>

        <div class="interval"></div>

        <div class="slicer-parmars">
          <div class="top">
            <div class="title">
              <i class="iconfont icon-a-shuzhixuanze1"></i>
              <div class="txt">
                <p class="parmars">树脂-切片参数</p>
                <div class="set">设置你的树脂以及打印设置</div>
              </div>
            </div>
            <div
              class="configFileSelect"
              ref="iptConfigFile"
              @click="selectConfigFile"
            >
              {{ pararmsForm.confName }}
              <i class="iconfont el-icon-arrow-down"></i>
            </div>

            <div
              class="save"
              @click="isShowSaveConfiDialog = true"
              :class="{ active: isShowSaveConfiDialog }"
            >
              <i class="icon-a-Frame1 iconfont"></i>
              <span>另存为</span>
            </div>
          </div>
          <div class="print-advanced-set">
            <div
              class="print"
              @click="isShowPrintSet = true"
              :style="{
                borderBottom: isShowPrintSet ? '3px solid #d71518' : 'none',
              }"
            >
              打印设置
            </div>
            <div
              class="advanced"
              @click="isShowPrintSet = false"
              :style="{
                borderBottom: !isShowPrintSet ? '3px solid #d71518' : 'none',
              }"
            >
              高级设置
            </div>
          </div>
          <div class="print-parmars" v-if="isShowPrintSet">
            <el-form
              label-position="top"
              label-width="80px"
              :model="pararmsForm"
              ref="pararmsForm"
            >
              <div class="left">
                <el-form-item>
                  <div class="label">
                    <span class="name">树脂类型</span>
                    <span class="des">打印所用的树脂材料类型</span>
                  </div>
                  <el-select
                    v-model="pararmsForm.resinType"
                    placeholder="请选择"
                    @change="changPararms($event, 'resinType')"
                  >
                    <el-option
                      v-for="item in options.resin"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <div class="label">
                    <span class="name">层厚</span>
                    <span class="des">层厚范围在0.1～0.025mm。</span>
                  </div>
                  <el-input-number
                    :controls="false"
                    :precision="3"
                    :min="0.025"
                    :max="0.1"
                    v-model="pararmsForm.layerThickness"
                    @change="changPararms($event, 'layerThickness')"
                  ></el-input-number>
                  <div class="mm">mm</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'layerThickness', '+', 0.01)"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'layerThickness', '-', 0.01)"
                  ></div>
                </el-form-item>
                <el-form-item label="单层曝光时间">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.layerExposure"
                    @change="changPararms($event, 'layerExposure')"
                  ></el-input-number>
                  <div class="ms">s</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'layerExposure', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'layerExposure', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="底层曝光时间">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.bottomExposure"
                    @change="changPararms($event, 'bottomExposure')"
                  ></el-input-number>
                  <div class="ms">s</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'bottomExposure', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'bottomExposure', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="曝光延迟">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.exposureDelay"
                    @change="changPararms($event, 'exposureDelay')"
                  ></el-input-number>
                  <div class="ms">s</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'exposureDelay', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'exposureDelay', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="底层曝光层数">
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    v-model="pararmsForm.bottomExposureLayers"
                    @change="changPararms($event, 'bottomExposureLayers')"
                  ></el-input-number>
                  <div class="n">n</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'bottomExposureLayers', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'bottomExposureLayers', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="底层曝光过渡层数">
                  <el-input-number
                    :controls="false"
                    :precision="0"
                    v-model="pararmsForm.bottomExposureTransition"
                    @change="changPararms($event, 'bottomExposureTransition')"
                  ></el-input-number>
                  <div class="n">n</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'bottomExposureTransition', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'bottomExposureTransition', '-')"
                  ></div>
                </el-form-item>
              </div>
              <div class="right">
                <el-form-item label="抬升高度1">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.liftingHeightOne"
                    @change="changPararms($event, 'liftingHeightOne')"
                  ></el-input-number>
                  <div class="mm">mm</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'liftingHeightOne', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'liftingHeightOne', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="抬升速度1">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.liftingSpeedOne"
                    @change="changPararms($event, 'liftingSpeedOne')"
                  ></el-input-number>
                  <div class="mm-min">mm/min</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'liftingSpeedOne', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'liftingSpeedOne', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="抬升高度2">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.liftingHeightTwo"
                    @change="changPararms($event, 'liftingHeightTwo')"
                  ></el-input-number>
                  <div class="mm">mm</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'liftingHeightTwo', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'liftingHeightTwo', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="抬升速度2">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.liftingSpeedTwo"
                    @change="changPararms($event, 'liftingSpeedTwo')"
                  ></el-input-number>
                  <div class="mm-min">mm/min</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'liftingSpeedTwo', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'liftingSpeedTwo', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="下降速度">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.declineSpeed"
                    @change="changPararms($event, 'declineSpeed')"
                  ></el-input-number>
                  <div class="mm-min">mm/min</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'declineSpeed', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'declineSpeed', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="打印完Z轴抬升">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.zaxisLiftComplete"
                    @change="changPararms($event, 'zaxisLiftComplete')"
                  ></el-input-number>
                  <div class="mm">mm</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'zaxisLiftComplete', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'zaxisLiftComplete', '-')"
                  ></div>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="print-parmars" v-else>
            <el-form
              label-position="top"
              label-width="80px"
              :model="pararmsForm"
              ref="pararmsForm"
            >
              <div class="left">
                <el-form-item label="内径补偿">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.insideDiameterCompensation"
                    @change="changPararms($event, 'insideDiameterCompensation')"
                  ></el-input-number>
                  <div class="mm">mm</div>
                  <div
                    class="add"
                    @click="
                      changPararms(null, 'insideDiameterCompensation', '+')
                    "
                  ></div>
                  <div
                    class="sub"
                    @click="
                      changPararms(null, 'insideDiameterCompensation', '-')
                    "
                  ></div>
                </el-form-item>
                <el-form-item label="外径补偿">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.outsideDiameterCompensation"
                    @change="
                      changPararms($event, 'outsideDiameterCompensation')
                    "
                  ></el-input-number>
                  <div class="mm">mm</div>
                  <div
                    class="add"
                    @click="
                      changPararms(null, 'outsideDiameterCompensation', '+')
                    "
                  ></div>
                  <div
                    class="sub"
                    @click="
                      changPararms(null, 'outsideDiameterCompensation', '-')
                    "
                  ></div>
                </el-form-item>
                <el-form-item label="X轴缩放补偿">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.xaxisScaleCompensation"
                    @change="changPararms($event, 'xaxisScaleCompensation')"
                  ></el-input-number>
                  <div class="per">%</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'xaxisScaleCompensation', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'xaxisScaleCompensation', '-')"
                  ></div>
                </el-form-item>
                <el-form-item label="Y轴缩放补偿">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.yaxisScaleCompensation"
                    @change="changPararms($event, 'yaxisScaleCompensation')"
                  ></el-input-number>
                  <div class="per">%</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'yaxisScaleCompensation', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'yaxisScaleCompensation', '-')"
                  ></div>
                </el-form-item>
                <!-- <el-form-item class="support">
                  <span>自动支撑</span>
                  <el-switch
                    v-model="pararmsForm.isAutoSupport"
                    active-color="#D71518"
                    inactive-color="#DDDFE6"
                  >
                  </el-switch>
                </el-form-item>
                <el-form-item>
                  <div class="label">
                    <span class="name">支撑密度</span>
                    <span class="des">数值越大添加的支撑越密集</span>
                  </div>
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    v-model="pararmsForm.supportDensity"
                  ></el-input-number>
                  <div class="per">%</div>
                  <div
                    class="add"
                    @click="machineParame.distinguishability.horz++"
                  ></div>
                  <div
                    class="sub"
                    @click="machineParame.distinguishability.horz--"
                  ></div>
                </el-form-item>
                <el-form-item class="support">
                  <span>添加底座</span>
                  <el-switch
                    v-model="pararmsForm.isAddBase"
                    active-color="#D71518"
                    inactive-color="#DDDFE6"
                  >
                  </el-switch>
                </el-form-item> -->
              </div>
              <div class="right">
                <el-form-item label="Z轴缩放补偿">
                  <el-input-number
                    :controls="false"
                    :precision="2"
                    :min="0"
                    v-model="pararmsForm.zaxisScaleCompensation"
                    @change="changPararms($event, 'zaxisScaleCompensation')"
                  ></el-input-number>
                  <div class="per">%</div>
                  <div
                    class="add"
                    @click="changPararms(null, 'zaxisScaleCompensation', '+')"
                  ></div>
                  <div
                    class="sub"
                    @click="changPararms(null, 'zaxisScaleCompensation', '-')"
                  ></div>
                </el-form-item>
                <el-form-item>
                  <div class="label">
                    <span class="name">抗锯齿</span>
                    <span class="des">等级为“1”时不开启抗锯齿</span>
                  </div>
                  <el-select
                    v-model="pararmsForm.antiAliasingLevel"
                    placeholder="请选择"
                    @change="changPararms($event, 'antiAliasingLevel')"
                  >
                    <el-option
                      v-for="item in options.antiAliasing"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="灰度级别">
                  <el-select
                    v-model="pararmsForm.grayLevel"
                    placeholder="请选择"
                    @change="changPararms($event, 'grayLevel')"
                  >
                    <el-option
                      v-for="item in options.grayLevel"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="图像模糊像素">
                  <el-select
                    v-model="pararmsForm.imageBlurPixel"
                    placeholder="请选择"
                    @change="changPararms($event, 'imageBlurPixel')"
                  >
                    <el-option
                      v-for="item in options.fog"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="submit">
            <el-button type="primary" @click="sumbitPrintSelect"
              >确定</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="user-define-dialog">
      <el-dialog
        :visible.sync="isShowUserDefinedMachine"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
      >
        <UserDefinedMachine
          @closeUserDefineDialog="
            isShowUserDefinedMachine = false;
            getAllMachines();
          "
        />
      </el-dialog>
    </div>
    <div class="manage-machine-dialog">
      <el-dialog
        :visible.sync="isShowManageMachine"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
        @close="closeDemo"
      >
        <ManageMachine
          v-if="isShowManageMachine"
          :machineParameters="machines[selectedMachineIndex]"
          @closeManageMachineDialog="manageMachineDialog"
        >
        </ManageMachine>
      </el-dialog>
    </div>
    <div class="configFile-machine-dialog">
      <el-dialog
        v-if="isShowConfigFileMachine"
        :visible.sync="isShowConfigFileMachine"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
      >
        <ConfigFileMachine
          :configFileList="machines[selectedMachineIndex].configurationList"
          :selectConfigFileIndex="selectConfigFileIndex"
          @closeConfigFileMachineDialog="configFileMachineDialog"
          @toggleConfIndex="selectConfigFileIndex = $event"
        >
        </ConfigFileMachine>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UserDefinedMachine from "./userDefinedMachine.vue";
import ManageMachine from "./machineManage.vue";
import ConfigFileMachine from "./configFile.vue";
import { getUserPrintInfo } from "../api/getInfo.js";
import { addPrinterConfiguration } from "../api/getInfo";
export default {
  name: "SetPrinter",
  props: {
    dialogVisible: Boolean,
  },
  components: {
    UserDefinedMachine,
    ManageMachine,
    ConfigFileMachine,
  },
  data() {
    return {
      //搜索机型的名字
      searchMachineName: "",
      searchMachines: [],
      //新的配置文件的名字
      newConfName: "",
      //显示保存配置文件弹窗
      isShowSaveConfiDialog: false,
      isShowPrintSet: true,
      //配置文件对应的参数
      pararmsForm: {
        resinType: "",
        layerThickness: 0.05,
        layerExposure: 8000,
        bottomExposure: 600000,
        exposureDelay: 1.0,
        bottomExposureLayers: 20,
        bottomExposureTransition: 6,
        liftingHeightOne: 3,
        liftingSpeedOne: 40,
        liftingHeightTwo: 3,
        liftingSpeedTwo: 100,
        declineSpeed: 100,
        zaxisLiftComplete: 100,

        insideDiameterCompensation: 0.0,
        outsideDiameterCompensation: 0.0,
        xaxisScaleCompensation: 0.0,
        yaxisScaleCompensation: 0.0,
        zaxisScaleCompensation: 0.0,
        antiAliasingLevel: 3,
        grayLevel: 1,
        imageBlurPixel: 2,
        isAutoSupport: 1, //是否自动支撑(0:否，1:是)
        supportDensity: 50,
        isAddBase: 1, //是否添加底座(0:否，1:是)
      },
      //是否展示管理机型弹窗
      isShowManageMachine: false,
      //是否展示添加自定义机型
      isShowUserDefinedMachine: false,
      //是否显示配置文件弹窗
      isShowConfigFileMachine: false,
      //选择的打印机索引值
      selectedMachineIndex: 0,
      //选择打印机配置文件的索引值
      selectConfigFileIndex: 0,
      isDelMachine: false,
      input: "",
      antiAliasingValue: [1, 2, 4, 6, 8, 16],
      configFile: [
        {
          name: "配置文件-BLOOM 760",
          resinName: "标准刚性树脂+",
          depth: 0.05,
          scalCompensation: {
            XY: 0,
            Z: 0,
          },
          autoSupport: false,
          antiAliasing: false,
        },
        {
          name: "配置文件-BLOOM 761",
        },
        {
          name: "配置文件-BLOOM 762",
        },
        {
          name: "配置文件-BLOOM 763",
        },
        {
          name: "配置文件-BLOOM 764",
        },
      ],
      configFileName: "",
      isUnfold: true,
      isfoldAntialsing: false,
      machines: [
        {
          isDefault: true,
          baseConfig: {
            name: "1",
            printSize: {
              x: 50,
              y: 50,
              z: 50,
            },
            distinguishability: {
              horz: 0,
              vert: 0,
            },
            imageUrl: "",
          },
          configFile: [
            {
              name: "配置文件-BLOOM 760",
              resinName: "标准刚性树脂+",
              depth: 0.05,
              scalCompensation: {
                XY: 0,
                Z: 0,
              },
              autoSupport: false,
              antiAliasing: false,
            },
            {
              name: "配置文件-BLOOM 761",
            },
            {
              name: "配置文件-BLOOM 762",
            },
            {
              name: "配置文件-BLOOM 763",
            },
            {
              name: "配置文件-BLOOM 764",
            },
          ],
        },
        {
          isDefault: true,
          baseConfig: {
            name: "",
            printSize: {
              x: 50,
              y: 50,
              z: 50,
            },
            distinguishability: {
              horz: 0,
              vert: 0,
            },
            imageUrl: "",
          },
          configFile: [
            {
              name: "配置文件-BLOOM 760",
              resinName: "标准刚性树脂+",
              depth: 0.05,
              scalCompensation: {
                XY: 0,
                Z: 0,
              },
              autoSupport: false,
              antiAliasing: false,
            },
            {
              name: "配置文件-BLOOM 761",
            },
            {
              name: "配置文件-BLOOM 762",
            },
            {
              name: "配置文件-BLOOM 763",
            },
            {
              name: "配置文件-BLOOM 764",
            },
          ],
        },
        {
          isDefault: true,
          baseConfig: {
            name: "",
            printSize: {
              x: 50,
              y: 50,
              z: 50,
            },
            distinguishability: {
              horz: 0,
              vert: 0,
            },
            imageUrl: "",
          },
          configFile: [
            {
              name: "配置文件-BLOOM 760",
              resinName: "标准刚性树脂+",
              depth: 0.05,
              scalCompensation: {
                XY: 0,
                Z: 0,
              },
              autoSupport: false,
              antiAliasing: false,
            },
            {
              name: "配置文件-BLOOM 761",
            },
            {
              name: "配置文件-BLOOM 762",
            },
            {
              name: "配置文件-BLOOM 763",
            },
            {
              name: "配置文件-BLOOM 764",
            },
          ],
        },
      ],
      // dialogVisible: false
      options: {
        resin: [
          {
            value: "标准刚性树脂+",
            label: "标准刚性树脂+",
          },
          {
            value: "类ABS树脂+",
            label: "类ABS树脂+",
          },
          {
            value: "水洗树脂+",
            label: "水洗树脂+",
          },
          {
            value: "植物基树脂",
            label: "植物基树脂",
          },
        ],
        antiAliasing: [
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "8",
            label: "8",
          },
          {
            value: "16",
            label: "16",
          },
        ],
        grayLevel: [
          {
            value: "0",
            label: "0",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
        ],
        fog: [
          {
            value: "0",
            label: "0",
          },
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          },
          {
            value: "8",
            label: "8",
          },
        ],
      },
      value: "",
    };
  },
  methods: {
    //提交
    sumbitPrintSelect() {
      this.$emit(
        "closeSetPrinterDialog",
        this.machines[this.selectedMachineIndex],
        this.selectConfigFileIndex
      );
    },
    //搜索机型
    searchMachinesFunc(ipt) {
      const machines = [];
      console.log(this.machines);
      for (let machine of this.machines) {
        if (machine.name.includes(ipt)) {
          machines.push(machine);
        }
      }
      this.searchMachines = machines;
      this.selectedMachineIndex = 0;
      console.log("this.searchMachines", this.searchMachines);
      if (this.searchMachines.length === 0) {
        for (let prop in this.pararmsForm) {
          this.pararmsForm[prop] = "";
        }
        console.log("this.pararmsForm", this.pararmsForm);
      } else {
        this.pararmsForm =
          this.searchMachines[this.selectedMachineIndex].configurationList[0];
      }

      console.log(machines);
    },
    //配置文件关闭后退到打印机选择弹窗的操作
    configFileMachineDialog(isDel) {
      if (isDel) {
        this.selectConfigFileIndex = 0;
        this.getAllMachines();
      }
      this.isShowConfigFileMachine = false;
      this.pararmsForm =
        this.machines[this.selectedMachineIndex].configurationList[
          this.selectConfigFileIndex
        ];
    },
    //管理机型关闭后退到打印机选择弹窗的操作
    manageMachineDialog(isDel) {
      console.log("isDel", isDel);
      if (isDel) {
        this.selectedMachineIndex = 0;
      }
      this.getAllMachines();
      this.isShowManageMachine = false;
    },
    closeDemo() {
      console.log("close");
    },
    closeDialog() {
      this.$emit("closeSetPrinterDialog");
    },
    async getAllMachines() {
      const res = await getUserPrintInfo();
      this.machines = [...res.rows];
      this.searchMachines = this.machines;
      console.log("all", this.machines);
      this.pararmsForm =
        this.machines[this.selectedMachineIndex].configurationList[0];
    },
    selectMachine(index) {
      this.selectedMachineIndex = index;
      this.selectConfigFileIndex = 0;
      this.pararmsForm =
        this.machines[this.selectedMachineIndex].configurationList[0];
      //将下面的参数进行复制、配置文件形成
    },
    manageMachine(index) {
      console.log("manageMachine");
      this.selectedMachineIndex = index;
      this.pararmsForm =
        this.machines[this.selectedMachineIndex].configurationList[0];
      this.isShowManageMachine = true;
    },
    changPararms(val, prop, op, incrementValue) {
      if (op === "+") {
        this.pararmsForm[prop] += (incrementValue !== undefined) ? incrementValue : 1;
      } else if (op === "-") {
        this.pararmsForm[prop] -= (incrementValue !== undefined) ? incrementValue : 1;
      } else {
        this.pararmsForm[prop] = val;
      }
      console.log("val", val);
    },
    //选择配置文件
    selectConfigFile() {
      this.isShowConfigFileMachine = true;
    },

    //保存新的配置文件
    async saveNewConfig() {
      if (this.newConfName) {
        let parmars = {
          ...this.pararmsForm,
          confName: this.newConfName,
          lang: "",
          isDefault: 0,
        };
        //上传新的配置文件
        const res = await addPrinterConfiguration(parmars);
        if (res.code === 200) {
          this.$message({
            message: "新的配置保存成功",
            type: "success",
          });
          this.getAllMachines();
        } else {
          this.$message.error(
            "保存失败,您的切片参数不能为空或者您的配置文件名字有冲突"
          );
        }
        this.isShowSaveConfiDialog = false;
      } else {
        this.$message({
          showClose: true,
          message: "配置文件名字不能为空",
          type: "error",
        });
      }
    },
  },
  async mounted() {
    this.configFileName = this.configFile[0].name;
    //获取所有机型
    this.getAllMachines();
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
div.add,
div.sub {
  // color: #5A5A5A !important;
}
li {
  list-style: none;
}
.icon-guanli {
  font-size: 14px;
  margin-right: 2px;
  margin-top: 2px;
}
.set-printer {
  .set-printer-dialog {
    /deep/ .el-dialog {
      width: 750px;
      height: 760px;
      background: #ffffff;
      border-radius: 10px;
      .el-dialog__header {
        padding: 0;
      }
      i.el-icon-close {
        position: absolute;
        right: -60px;
        top: 0;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 150px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      .el-dialog__body {
        padding: 0;
      }
      //打印机选择
      .top {
        display: flex;
        justify-content: space-between;
        padding: 30px 50px 0 50px;
        .title {
          display: flex;
          margin-right: 81px;
          i.icon-a-Group14154 {
            font-size: 35px;
            color: #d71518;
          }
          .txt {
            width: 140px;
            .select {
              font-weight: 600;
              font-size: 16px;
              color: #000000;
              margin: 0;
            }
            .newAdd {
              font-weight: 400;
              font-size: 12px;
              color: #5a5a5a;
              margin: 0;
            }
          }
        }
        .el-input {
          width: 210px;
          margin-right: -40px;
          .el-input__inner {
            width: 210px;
            height: 28px;
            background: #eaecf0;
            border-radius: 5px;
          }
          .el-icon-search {
            margin-top: -5px;
            font-size: 16px;
          }
        }
        .manageMachine {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 95px;
          height: 28px;
          border-radius: 5px;
          color: #d71518;
          cursor: pointer;
          font-size: 12px;
          border: 1px solid #d71518;
        }
      }
      .banner {
        padding: 0px 65px 0 50px;
        margin-top: 10px;
        ul {
          width: 600px;
          height: 150px;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          li {
            display: inline-block;
            width: 94px;
            height: 120px;
            // white-space: normal;
            margin-right: 14px;
            background: #eaecf0;
            border-radius: 5px;
            .pic {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100px;
              i.el-icon-check {
                position: absolute;
                top: 12px;
                left: 70px;
                width: 15px;
                height: 15px;
                background-color: #25d715;
                border-radius: 50%;
                font-size: 16px;
                // transform: scale(0.6);
                color: #fff;
                line-height: 15px;
                text-align: center;
                cursor: pointer;
              }
              i.icon-bianji {
                position: absolute;
                top: 12px;
                left: 70px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                font-size: 18px;
                color: #708ffe;
                line-height: 15px;
                text-align: center;
                cursor: pointer;
              }
              img {
                width: 42px;
                height: 66px;
                margin: 0 auto;
                object-fit: cover;
              }
            }
            .name {
              width: 100%;
              height: 20px;
              background: linear-gradient(90deg, #f14c41 -4.38%, #ff8f88 100%);
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              color: #fff;
              border-end-start-radius: 5px;
              border-end-end-radius: 5px;
            }
          }
          li:hover {
            border: 1px solid #d71518;
            .name {
              border-bottom: 1px solid #d71518;
            }
          }
          li:last-child {
            transform: translateY(-40px);
          }
          div.addMachine {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i.el-icon-plus {
              font-size: 50px;
              color: #ccc;
            }
            span.add {
              color: #ccc;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
      .interval {
        width: 100%;
        height: 5px;
        margin-top: 15px;
        background: #eaecf0;
      }
      .slicer-parmars {
        .top {
          .title {
            i.icon-a-shuzhixuanze1 {
              font-size: 35px;
              color: red;
            }
            .txt {
              width: 150px;
              .parmars {
                font-weight: 600;
                font-size: 16px;
                color: #000000;
                margin: 0;
              }
              .set {
                font-weight: 400;
                font-size: 12px;
                color: #5a5a5a;
                margin: 0;
              }
            }
          }
          .configFileSelect {
            position: relative;
            width: 210px;
            height: 28px;
            background: #eaecf0;
            border-radius: 5px;
            color: #000;
            line-height: 28px;
            padding-left: 13px;
            cursor: pointer;
            i.el-icon-arrow-down {
              position: absolute;
              z-index: 999;
              right: 10px;
              font-weight: 600;
              top: 10px;
              font-size: 12px;
            }
          }
          .save {
            display: flex;
            justify-content: center;
            width: 95px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
            border: 1px solid #d71518;
            cursor: pointer;
            i.icon-a-Frame1 {
              font-size: 18px;
              margin-right: 2px;
              color: #d71518;
            }
            span {
              font-size: 12px;
              color: #d71518;
            }
          }
          .save.active {
            background-color: #d71518;
            i.icon-a-Frame1 {
              color: #fff;
            }
            span {
              color: #fff;
            }
          }
        }
        .print-advanced-set {
          margin-top: 30px;
          display: flex;
          justify-content: space-evenly;
          padding: 0 77px 0 69px;
          font-weight: 500;
          font-size: 12px;
          color: #000;
          div {
            padding-bottom: 5px;
            border-bottom: 3px solid #d71518;
            cursor: pointer;
          }
        }
        //打印参数
        .print-parmars {
          padding: 0 0 0 68px;
          margin-right: 21px;
          margin-top: 10px;
          height: 300px;
          // overflow: scroll;
          overflow-y: auto;
          .el-form {
            display: flex;
            .el-form-item {
              .el-form-item__content {
                position: static;
              }
              width: 280px;
              position: relative;
              margin-bottom: 14px;
              .mm,
              .px,
              .ms,
              .mm-min,
              .n,
              .per {
                position: absolute;
                right: 22px;
                top: 18px;
                color: #ccc;
                font-size: 12px;
                font-weight: 400;
              }
              .px,
              .per {
                right: 26px;
              }
              .add {
                position: absolute;
                right: 0px;
                top: 24px;
                width: 20px;
                height: 14px;
                cursor: pointer;
                &::before {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  content: "";
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-bottom: 5px solid #5a5a5a;
                }
              }
              .sub {
                position: absolute;
                right: 0px;
                top: 39px;
                width: 20px;
                height: 14px;
                cursor: pointer;
                &::before {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  content: "";
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-top: 5px solid #5a5a5a;
                }
              }
              .label {
                display: flex;
                align-items: center;
                padding-left: 18px;
                line-height: normal;
                height: 17px;
                margin-bottom: 2px;
                .des {
                  margin-left: 15px;
                  font-weight: 400;
                  color: #ccc;
                  font-size: 12px;
                }
              }
              .el-form-item__label {
                padding: 0;
                padding-left: 18px;
                line-height: normal;
              }
            }
            .el-input {
              input {
                width: 280px;
                height: 30px;
                background: #f5f7fb;
                text-align: left;
              }
            }
            .left {
              margin-right: 44px;
              .support {
                .el-form-item__content {
                  padding-left: 18px;
                }
              }
              .el-switch {
                float: right;
                margin-top: 10px;
                span.el-switch__core {
                  border-color: #fff !important;
                }
                span.el-switch__core::after {
                  background-color: #fff;
                }
              }
              .is-checked {
                span.el-switch__core {
                  border-color: #fff !important;
                }
                span.el-switch__core::after {
                  background-color: #fff;
                }
              }
            }
          }
        }
        //submit
        .submit {
          display: flex;
          justify-content: center;
          margin-top: 29px;
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 81px;
            height: 28px;
            background: #d71518;
            border-radius: 5px;
            span {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
      .saveConfigDialog {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 380px;
        height: 200px;
        padding: 15px 40px 0;
        transform: translate(-50%, -50%);
        background: #ffffff;
        z-index: 99999;
        border-radius: 5px;
        box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);
        i.el-icon-close {
          position: absolute;
          right: 12px;
          top: 10px;
          font-size: 20px;
          color: #000;
          cursor: pointer;
          line-height: 26px;
        }
        .title {
          height: 22px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          color: #000000;
        }
        .el-input {
          margin-top: 44px;
          margin-bottom: 32px;
          input {
            width: 300px;
            height: 30px;
            background: #f5f7fb;
            border-radius: 5px;
          }
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          width: 120px;
          height: 30px;
          margin: 0 auto;
          background: #d71518;
          border-radius: 5px;
        }
      }
    }
  }
  .user-define-dialog {
    /deep/ .el-dialog {
      width: 750px;
      height: 760px;
      border-radius: 10px;
      padding: 30px 0 0 50px;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
      }
      i.el-icon-close {
        position: absolute;
        right: -60px;
        top: 0;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 150px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      h1.title {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      .addForm {
        display: flex;
        margin-top: 42px;
        .el-form-item__error {
          padding-top: 0;
        }
        .left {
          width: 280px;
          margin-right: 60px;
          .el-form-item__label {
            height: 17px;
            margin-bottom: 10px;
            line-height: 17px;
            font-size: 1px;
            font-weight: 500;
          }
          .el-form-item {
            position: relative;
            margin-bottom: 10px;
            .mm,
            .px {
              position: absolute;
              right: 20px;
              top: -2px;
              color: #ccc;
              font-size: 12px;
              font-weight: 400;
            }
            .px {
              right: 28px;
            }
            .add {
              position: absolute;
              right: 6px;
              top: 12px;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid #ccc;
              cursor: pointer;
            }
            .sub {
              position: absolute;
              right: 6px;
              top: 22px;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #ccc;
              cursor: pointer;
            }
          }
          .el-input {
            input {
              width: 280px;
              height: 30px;
              background: #f5f7fb;
              border-radius: 5px;
              text-align: left;
            }
          }
          .printSize {
            margin-top: 20px;
            .title {
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 6px;
            }
          }
          .distinguishability {
            margin-top: 20px;
            .title {
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 10px;
            }
          }
        }
        .right {
          .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 120px;
            height: 120px;
            line-height: 120px;
            text-align: center;
            background-color: #f5f7fb;
          }
          .avatar {
            width: 120px;
            height: 120px;
            display: block;
          }
        }
      }
      .el-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 81px;
        height: 28px;
        margin: 80px auto;
        font-size: 12px;
        background: #d71518;
        border-radius: 5px;
      }
    }
  }
  .manage-machine-dialog {
    /deep/ .el-dialog {
      width: 750px;
      height: 760px;
      border-radius: 10px;
      padding: 30px 0 0 50px;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
      }
      i.el-icon-close {
        position: absolute;
        right: -60px;
        top: 0;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 150px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      h1.title {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      h1.set-name {
        display: flex;
        font-size: 16px;
        .el-input {
          width: 80px;
          height: 22px;
          // border-bottom: 1px solid #ccc;
          input {
            padding: 0;
            height: 20px;
            border: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .el-input__suffix {
            right: -22px;
          }
          .el-input__icon {
            line-height: 0;
          }
        }
        .custom {
          height: 20px;
          color: #ccc;
          margin-left: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
        }
      }
      .addForm {
        display: flex;
        margin-top: 42px;
        .el-form-item__error {
          padding-top: 0;
        }
        .left {
          width: 280px;
          margin-right: 60px;
          .el-form-item__label {
            height: 17px;
            margin-bottom: 10px;
            line-height: 17px;
            font-size: 1px;
            font-weight: 500;
          }
          .el-form-item {
            position: relative;
            margin-bottom: 10px;
            .mm,
            .px {
              position: absolute;
              right: 20px;
              top: -2px;
              color: #ccc;
              font-size: 12px;
              font-weight: 400;
            }
            .px {
              right: 28px;
            }
            .add {
              position: absolute;
              right: 6px;
              top: 12px;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid #ccc;
              cursor: pointer;
            }
            .sub {
              position: absolute;
              right: 6px;
              top: 22px;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #ccc;
              cursor: pointer;
            }
          }
          .el-input {
            input {
              width: 280px;
              height: 30px;
              background: #f5f7fb;
              border-radius: 5px;
              text-align: left;
            }
          }
          .printSize {
            margin-top: 20px;
            .title {
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 6px;
            }
          }
          .distinguishability {
            margin-top: 20px;
            .title {
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 10px;
            }
          }
        }
        .right {
          .pic {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 216px;
            height: 255px;
            background: #f5f7fb;
            border-radius: 5px;
            img {
              width: 121px;
              height: 194px;
            }
          }
          .update {
            width: 216px;
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            color: #000;
            input {
              display: none;
            }
            span {
              cursor: pointer;
            }
          }
        }
      }
      .btns {
        display: flex;
        justify-content: center;
        .el-button {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 81px;
          height: 28px;
          margin: 80px 0 0;
          font-size: 12px;
          background: #d71518;
          border-radius: 5px;
        }
        .del {
          background-color: #fff;
          color: #d71518;
          margin-left: 35px;
          border: 1px solid #d71518;
        }
      }
    }
  }
  .configFile-machine-dialog {
    /deep/ .el-dialog {
      width: 750px;
      height: 760px;
      border-radius: 10px;
      padding: 30px 0 0 50px;
      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
      }
      i.el-icon-close {
        position: absolute;
        right: -60px;
        top: 0;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 150px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
      .top {
        display: flex;
        align-items: center;
        .label {
          font-size: 16px;
          font-weight: 500;
          color: #000;
        }
        .value {
          .select {
            margin: 0 5px 0 19px;
            font-size: 12px;
          }
          .name {
            font-size: 12px;
            color: #d71518;
          }
        }
      }
      .config {
        display: flex;
        margin-top: 20px;
        .left {
          width: 270px;
          height: 570px;
          padding: 20px 35px 0 20px;
          background: #f5f7fb;
          border-radius: 5px;
          overflow-y: auto;
          .default {
            .title {
              font-size: 12px;
              font-weight: 500;
              color: #000;
            }
            .value {
              cursor: pointer;
              width: 210px;
              height: 85px;
              padding: 10px 0 0 15px;
              margin-top: 15px;
              background: #ffffff;
              // border: 1px solid #d71518;
              margin-bottom: 20px;
              border-radius: 5px;
              .name {
                margin-bottom: 14px;
                font-size: 12px;
                color: #000;
                font-weight: 500;
              }
              .resin,
              .dep {
                height: 17px;
                line-height: 17px;
                font-size: 12px;
                color: #5a5a5a;
              }
            }
          }
          .userDefine {
            margin-bottom: 15px;
            .title {
              font-size: 12px;
              font-weight: 500;
              color: #000;
            }
            .value {
              cursor: pointer;
              width: 210px;
              height: 85px;
              padding: 10px 0 0 15px;
              margin-top: 15px;
              background: #ffffff;
              // border: 1px solid #d71518;
              border-radius: 5px;
              .name {
                margin-bottom: 14px;
                font-size: 12px;
                color: #000;
                font-weight: 500;
              }
              .bottom {
                display: flex;
                .left-content {
                  .resin,
                  .dep {
                    height: 17px;
                    line-height: 17px;
                    font-size: 12px;
                    color: #5a5a5a;
                  }
                }
                .right-del {
                  cursor: pointer;
                  margin-top: 8px;
                  margin-left: 38px;
                  width: 50px;
                  height: 25px;
                  background: #ffffff;
                  line-height: 25px;
                  text-align: center;
                  border: 1px solid #d71518;
                  border-radius: 5px;
                  font-size: 12px;
                  color: #d71518;
                }
                .right-del.active {
                  background-color: #d71518;
                  color: #fff;
                }
              }
            }
          }
        }
        .right {
          flex: 1;
          .title {
            margin-left: 57px;
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }
          .resin,
          .type {
            display: flex;
            width: 430px;
            justify-content: space-between;
            padding: 0 103px 0 89px;
            margin-top: 15px;
            font-size: 12px;
            color: #5a5a5a;
          }
          .line {
            width: 300px;
            height: 0px;
            margin-left: 89px;
            margin-top: 15px;
            border: 1px solid #eaecf0;
          }
          .set {
            .top {
              display: flex;
              justify-content: center;
              margin-top: 30px;
              span {
                font-weight: 500;
                font-size: 12px;
                height: 27px;
                line-height: 27px;
                color: #5a5a5a;
                border-bottom: 3px solid #d71518;
                cursor: pointer;
              }
              span.print {
                margin-right: 56px;
              }
            }
            .par {
              padding: 0 103px 0 89px;
              li {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;
                color: #5a5a5a;
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }
      }
      .btns {
        margin: 45px 0 0 222px;
        button {
          width: 81px;
          height: 28px;
          background: #d71518;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          padding: 0;
        }
        .cancel {
          background-color: #fff;
          color: #d71518;
          border: 1px solid #d71518;
        }
      }
      .dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 380px;
        height: 200px;
        padding: 15px 10px 0;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.05);
        .tip {
          position: relative;
          font-weight: 500;
          color: #000;
          font-size: 16px;
          text-align: center;
          .icon-a-guanbi2 {
            position: absolute;
            top: -6px;
            right: 0;
            border: none;
            border-radius: 0;
            color: #333;
            font-size: 30px;
            cursor: pointer;
          }
        }
        .txt {
          font-size: 12px;
          margin: 45px;
          color: #000;
          text-align: center;
        }
        .btns {
          display: flex;
          justify-content: center;
          margin: 0;
          .confirm {
            margin-right: 45px;
          }
          .cancel {
            border: 1px solid #a0a0a0 !important;
            color: #a0a0a0;
          }
        }
      }
    }
  }
}
</style>