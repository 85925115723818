import axios from './index'

//用户跳转过来获取指定模型文件信息
const getJumpModelFileInfo = (modelFileIds) => {
  console.log('modelFileIds', modelFileIds);
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getModelFileList', {
    modelFileIds,
    lang
  })
}
//获取用户打印机型及配置列表
const getUserPrintInfo = () => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getCurrentUserPrinterTypeList', {
    lang
  })
}

//用户新增自定义机型
const addPrinterType = (form) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/addPrinterType', {...form,
    lang})
}

//上传自定义机型图片
const addPrinterPic = (formData) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/common/uploadToServer', formData)
}

//用户删除自定义机型
const delMachine = (id) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.get(`app/slicePreview/deletePrinterType/${id}`)
}

//用户更新自定义机型参数
const updatePrinterType = (form) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/updatePrinterType', form)
}

//用户新增自定义配置
const addPrinterConfiguration = (form) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/addPrinterConfiguration', form)
}

//用户删除自定义配置
const deletePrinterConfiguration = (id) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.get(`app/slicePreview/deletePrinterConfiguration?id=${id}`)
}

//搜索机型
const searchMachine = () => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('system/dict/data/list',{
    lang})
}

//获取当前用户可用模型文件列表
const getCurrentUserModelFileList = (id) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getCurrentUserModelFileList', {
    modelId: id,
    lang
  })
}

// 获取当前模型相关模型文件列表
const getCurrentModelList = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/getCurrentModelOtherFileList', {
    ...parmars,
    lang
  })
}

//根据模型名字搜索模型
const searchModelsByName = (search,pageNum) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/searchModelFileList', {
    search,
    lang,
    pageNum,
    pageSize: 10
  })
}

//上传切片时候的图片
const uploadSlicePic = (formData) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/common/uploadToServer', formData)
}

//开始切片
const slice = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/startSlice', parmars)
}

// 获取从模型库推荐的模型列表
const getRecommendModelList = (search,pageNum) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/model/getModelList', {
    "isRecommend": 1,
    lang,
    pageNum,
    "pageSize": 10,
    search
  })
}

// 获取模型对应的模型文件
const getModelFileListByModelID = (id) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.get(`/app/model/getModel?modelId=${id}&lang=${lang}`)
}

//更新配置文件
const updateConfigFile = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/slicePreview/updatePrinterConfiguration', parmars)
}

//根据路径获取模型
const getModelByPath = (parmars) => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'en') {
    lang = 'en_US'
  } else if(lang === 'ja') {
    lang = 'ja_JP'
  } else {
    lang = ''
  }
  return axios.post('app/common/downloadFromServer',parmars)
}

export {
  slice, updateConfigFile,getModelByPath,
  uploadSlicePic,
  getJumpModelFileInfo, searchModelsByName,
  getUserPrintInfo, addPrinterType, addPrinterPic, delMachine,
  updatePrinterType, addPrinterConfiguration, deletePrinterConfiguration,
  searchMachine, getCurrentUserModelFileList, getCurrentModelList, getRecommendModelList, getModelFileListByModelID
}