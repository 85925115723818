<template>
  <div class="preview">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div
        class="view-container"
        ref="viewContainer"
        v-loading="loadingCircle"
        element-loading-text="模型加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="top-nav">
          <i class="el-icon-arrow-left" @click="backFlutter()"></i>
          <span>{{ modelFile?.name?.split(".stl")[0] }}</span>
        </div>
        <div
          ref="slicer"
          class="slicer active"
          @click="$router.push(`/slicer?params=${params}`)"
          v-if="!defaultConfig.isFullScreen"
        >
          <i class="iconfont icon-qiepianpeizhi"></i>
          <span>{{$t('slices')}}</span>
        </div>
        <div id="model-preview" ref="canvasDom"></div>
        <!-- <el-progress
          type="circle"
          :percentage="percentage"
          v-if="loading"
        ></el-progress> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TrackballControls } from "three/examples/jsm/controls//TrackballControls.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { getJumpModelFileInfo } from "../api/getInfo";
import { STLLoader1 } from "../Load/PLALoader";
export default {
  name: "PreviewModel",
  data() {
    return {
      loadingCircle: true,
      loadTime: null,
      loadIntervalId: null,
      percentage: 0,
      //模型id
      modelId: "",
      loading: true,
      //用户跳转过来的模型文件信息
      modelFile: "",
      model: "",
      modelUrl: "",
      publicPath: process.env.BASE_URL,
      dialogVisible: true,
      scene: null,
      camera: null,
      renderer: null,
      orbitControls: null,
      trackballControls: null,
      loader: null,
      light: null,
      gridHelper: null,
      view: [
        {
          class: "icon-a-zhushitu1",
          activeClass: "icon-a-zhushitu2",
          name: "主视图",
        },
        {
          class: "icon-a-houshitu1",
          activeClass: "icon-a-houshitu2",
          name: "后视图",
        },
        {
          class: "icon-a-zuoshitu1",
          activeClass: "icon-a-zuoshitu2",
          name: "左视图",
        },
        {
          class: "icon-a-youshitu1",
          activeClass: "icon-a-youshitu2",
          name: "右视图",
        },
        {
          class: "icon-a-fushitu1",
          activeClass: "icon-a-fushitu2",
          name: "俯视图",
        },
        {
          class: "icon-a-yangshitu1",
          activeClass: "icon-a-yangshitu2",
          name: "仰视图",
        },
      ],
      viewIndex: 0,
      //展开设置
      isSetUnfold: true,
      //设置
      set: {
        colour: ["#006CFF", "#00D637", "#FF2222", "#FFB400"],
        displayMode: ["默认", "透明", "虚线"],
        background: ["深色", "浅色"],
      },
      defaultConfig: {
        colorIndex: 0,
        displayModeIndex: 0,
        backgroundIndex: 1,
        isShowGrid: false,
        isAutoRotate: false,
        isFullScreen: false,
        isShowSize: false,
      },
    };
  },
  methods: {
    init() {
      this.initScene();
      this.initCamera();
      this.initRenderer();
      this.initOrbitControls();
      // this.initTrackballControls();
      this.initLight();
      // this.initAxes();
      this.animate();

      // 监听浏览器窗口大小变化并更新renderer和camera等属性
      window.addEventListener("resize", () => {
        console.log("resize");
        var viewportWidth =
          window.innerWidth || document.documentElement.clientWidth;
        var viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        this.renderer.setSize(viewportWidth, viewportHeight);
        this.camera.aspect = viewportWidth / viewportHeight;
        this.camera.updateProjectionMatrix();
        // this.renderer.setPixelRatio(window.devicePixelRatio);
      });
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          console.log(
            "this.defaultConfig.isFullScreen",
            this.defaultConfig.isFullScreen
          );
          if (this.defaultConfig.isFullScreen) {
            // this.defaultConfig.isFullScreen = false
            this.fullScreen();
          }
          e.stopPropagation();
          e.preventDefault();
          console.log("esc");
        }
      });
    },
    //初始化场景
    initScene() {
      const scene = new THREE.Scene();
      this.scene = scene;
      scene.background = new THREE.Color("#ADAFB6");
    },
    //初始化相机
    initCamera() {
      var viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      var viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const camera = new THREE.PerspectiveCamera(
        75,
        viewportWidth / window.innerHeight,
        0.1,
        30000
      );
      this.camera = camera;
      camera.position.y = -300;
      // camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
      const target = new THREE.Vector3(0, 0, camera.position.z);
      camera.up = new THREE.Vector3(0, 0, 1);
      camera.lookAt(target);
    },
    //初始化渲染器
    initRenderer() {
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer = renderer;
      var viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      var viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      renderer.setSize(viewportWidth, viewportHeight);
      this.$nextTick(() => {
        this.$refs.canvasDom.appendChild(renderer.domElement);
      });
    },
    //初始化轨道控制器
    initOrbitControls() {
      const orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );

      this.orbitControls = orbitControls;
      //启用阻尼
      orbitControls.enableDamping = true;
      // orbitControls.dampingFactor = 1;
      //是否开启右键拖拽
      orbitControls.enablePan = true;
      orbitControls.update();
    },
    //初始化轨道控制器
    initTrackballControls() {
      const trackballControls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      trackballControls.enabled = true;
      this.trackballControls = trackballControls;
      console.log("trackballControls", trackballControls);

      /*  trackballControls.addEventListener("start", () => {
        trackballControls.enabled = true;
      }); */
    },
    //初始化光照
    initLight() {
      const ambienLight = new THREE.AmbientLight(0xfff, 0.1);
      this.scene.add(ambienLight);
      const light = new THREE.DirectionalLight(0xffffff, 1);
      this.light = light;
      light.position.set(0, 0, 0);
      this.scene.add(light);
    },
    animate() {
      this.orbitControls.update();
      if (this.defaultConfig.isAutoRotate) {
        this.model.rotation.z += 0.01;
      }
      this.light.position.set(
        this.camera.position.x,
        this.camera.position.y,
        this.camera.position.z
      );

      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    },
    //获得模型尺寸
    getModelSize(mesh) {
      // 获取模型的最大最小坐标
      const box = new THREE.Box3().setFromObject(mesh);
      const min = box.min;
      const max = box.max;
      // 计算模型的缩放比例
      const scale = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);
      const size = new THREE.Vector3();
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      return size;
    },
    loadStlModel(path) {
      if (!path) {
        path = this.modelUrl;
        console.log("path", path);
      }
      const loader = new STLLoader1();
      this.loader = loader;
      console.log("start load", Date.now());
      this.loadTime = Date.now();
      // #006CFF
      loader
        .loadAsync(
          path,

          (xhr) => {
            // console.log("load per", Date.now());
            let percentage = Number(
              ((xhr.loaded * 100) / xhr.total).toFixed(0)
            );
            let curTime = Date.now();
            if (curTime - this.loadTime >= 800) {
              this.$nextTick(() => {
                this.percentage = percentage;
                this.loadTime = curTime;
              });
            }

            // console.log(percentage, " %");
          }
        )
        .then((geometry) => {
          geometry.computeVertexNormals();
          const material = new THREE.MeshLambertMaterial({
            color: "#006CFF",
          });
          const mesh = new THREE.Mesh(geometry, material);
          geometry.center();
          this.scene.add(mesh);
          this.model = mesh;
          const size = this.getModelSize(mesh);
          this.model.size = size;
          /* var modelWidth = size.x
          var modelHeight = size.z
          var screenRatio = window.innerWidth / window.innerHeight;
          var modelRatio = modelWidth / modelHeight;
          if (screenRatio < modelRatio) {
            // 根据宽度进行缩放
            var scale = screenRatio / modelRatio;
            mesh.scale.set(scale, scale, scale);
          } else {
            // 根据高度进行缩放
            var scale = modelRatio / screenRatio;
            mesh.scale.set(scale, scale, scale);
          } */
          console.log("size", size);
          const radius = Math.sqrt(
            (size.x / 2) * (size.x / 2) +
              (size.y / 2) * (size.y / 2) +
              (size.z / 2) * (size.z / 2)
          );
          if (radius < size.y / 2) {
            radius = size.y / 2;
          }
          this.camera.position.y = -radius * 3;
          // this.gridHelper.position.z = -this.model.size.z / 2;
        })
        .then(() => {
          this.loading = false;
          this.loadingCircle = false;
          this.$refs.slicer.classList.remove("active");
          console.log("任务");
        })
        .catch((err) => {
          this.loadingCircle = false;
          this.$message({
            showClose: true,
            message: "模型加载失败",
            type: "error",
          });
          window.flutter_inappwebview.callHandler("fltSliceUp", "back"); // 返回调用
        });
    },
    async getJumpModelFileInfo() {
      const res = await getJumpModelFileInfo(this.params.modelFileIds);
      console.log("res", res);
      this.modelFile = res.rows[0];
      this.loading = true;
      this.loadStlModel(this.modelFile.url);
      // fetch(this.modelFile.url)
      //   .then((response) => {
      //     this.loadIntervalId = setInterval(() => {
      //       if (this.percentage < 99) {
      //         this.percentage += 1;
      //       }
      //     }, 100);
      //     return response.blob();
      //   })
      //   .then((blob) => {
      //     const blobUrl = URL.createObjectURL(blob);
      //     // 在此处使用临时URL进行操作，例如显示图像
      //     console.log("blobUrl", blobUrl);
      //     this.modelUrl = blobUrl;
      //     this.loadStlModel(blobUrl);
      //     console.log("load finish")
      //     // 在使用完临时URL后记得释放资源
      //     // URL.revokeObjectURL(blobUrl);
      //   })
      //   .catch((error) => {
      //     console.error("Failed to fetch the file:", error);
      //   });
      // console.log("pre", res);
    },
    backFlutter() {
      console.log(window.WebView);
      console.log(this.modelFile.name.split(".stl"));
      // window.fltSliceUp.postMessage("back");
      window.flutter_inappwebview.callHandler("fltSliceUp", "back");
    },
  },
  mounted() {
    //     36574908133376  400M的
    // 10993094111232  200M的
    // 在跳转到该页面时拿去模型id和模型文件id数组和语言
   /*  this.params = {
      //
      modelId: 10131762028544,
      // , 66203750809608  ,24790496337920  24940514009088 30987046895616 43956210221056
      modelFileIds: [9963859845120],
      // lang: 'en_US'
    }; */
    this.params = JSON.parse(decodeURIComponent(this.$route.query.params));
    /* let lang = this.params.lang;
    console.log("lang", lang);
    //传过来的参数lang
    if (lang === "en_US") {
      lang = "en";
    } else if(lang === "ja_JP"){
      console.log('ja');
      lang = "ja";
    } else {
      console.log('zh');
      lang = 'zh'
    }
    let localLang = localStorage.getItem("lang") || "zh";
    if (lang !== localLang) {
      localStorage.setItem("lang", lang);
      location.reload();
    } */
    //传过来的参数lang
   /*  if (lang === "en_US") {
      lang = "en";
    } else if(lang === 'ja-JP') {
      lang = 'ja'
    }
    else {
      lang = "zh";
    }
    let localLang = localStorage.getItem("lang") || "zh";
    if (lang !== localLang) {
      localStorage.setItem("lang", lang);
      location.reload();
    } */
    localStorage.setItem("token", this.params.token);
    this.getJumpModelFileInfo();
    //进行编码操作
    this.params = encodeURIComponent(JSON.stringify(this.params));
    ////用户跳转过来获取指定模型文件信息
    this.init();
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.iconfont {
  font-size: 20px;
}
.icon-a-shezhi2 {
  font-size: 25px;
}

/deep/ .el-loading-mask {
  .el-loading-spinner {
    .el-icon-loading {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
}
.common {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  span {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
  }
}
li {
  list-style: none;
}
.preview {
  width: 100%;
  height: 100%;
  /deep/ .el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/ .el-dialog {
    width: 100%;
    height: 100%;
    margin-top: 0 !important;
    margin-bottom: 0;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      border-radius: 8px;
      padding: 0;

      .view-container {
        position: relative;
        .top-nav {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 44px;
          padding: 0 20px;
          .el-icon-arrow-left {
            position: absolute;
            font-size: 20px;
            left: 20px;
            cursor: pointer;
          }
          span {
            font-size: 16px;
          }
        }
        #model-preview {
          height: calc(100vh - 44px);
          overflow: hidden;
        }

        //切片
        .slicer {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 10vh;
          width: 160px;
          height: 42px;
          text-align: center;
          color: #fff;
          background: #d71518;
          border-radius: 8px;
          cursor: pointer;
          span {
            margin-left: 15px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .slicer.active {
          display: none;
          // pointer-events: none;
          // background-color: pink;
        }
        .el-progress {
          position: absolute;
          z-index: 999;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>