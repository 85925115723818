// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// 引入各个语言配置文件
import zhLocale from '../lang/zh'
import enLocale from '../lang/en'
import jaLocale from '../lang/ja'

let lang =  JSON.parse(decodeURIComponent(window.location.href).split('?')[1].split('=')[1]).lang
if(lang === 'en_US') {
  lang = 'en'
} else if(lang === 'zh_CN') {
  lang = 'zh'
} else if(lang === 'ja_JP') {
  lang = 'ja'
}

localStorage.setItem('lang',lang) 
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  // locale: localStorage.getItem('lang') || 'zh', // 语言标识
  locale: lang,
  // locale: 'zh',
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    'zh': zhLocale,
    'en': enLocale,
    'ja': jaLocale
  }
})

// 暴露i18n
export default i18n

