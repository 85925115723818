import axios from 'axios'
axios.defaults.baseURL = 'https://code.core.octopusworks.com/'
// axios.defaults.baseURL = 'http://10.60.201.39:8080'
axios.interceptors.request.use(config => {
  let lang = localStorage.getItem('lang') || 'zh'
  if(lang === 'zh') {
    config.headers['Accept-Language'] = 'zh_CN'
  } else if(lang === 'en') {
    config.headers['Accept-Language'] = 'en_US'
  } else if(lang === 'ja') {
    config.headers['Accept-Language'] = 'ja-JP'
  }
  
  const token = localStorage.getItem('token')
  if(token) {
    config.headers.Authorization = 
    token
//  'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUwOTk5NmFkLTc2ZjAtNDdlMC1iMTMxLTFhNmJhY2RlNmU1NiJ9.kiKG4swzwkOxQ6Uudp2V5ZjxnBvpeMi_-sPyR2ZezfMtcrucS-mj-NXH0qb40pVeVHLonu3mKFr4FB4UMN-low'
       }
  return config
})
axios.interceptors.response.use(res => {
  // console.log('res',res);
  res = res.data
  if(res.code === 401) {
    //token 失效重新跳转到登录页面
    console.log('token失效');
    return  window.flutter_inappwebview.callHandler("fltSliceUp", "tokenOverdue");
  } else if(res.code === 409) {
    console.log('this',this);
    alert(res.msg)
    // confirm(res.msg)
    
    return  window.flutter_inappwebview.callHandler("fltSliceUp", "tokenOverdue");
  }
  return res
})
export default axios