export default {
  slices: '切片',
  support: '支撑',
  fine: '细',
  medium: '中等',
  coarse: '粗',
  slicer: {
    move: '移动',
    scale: '缩放',
    rotate: '旋转',
    autoLayout: '自动布局',
    copy: '复制',
    slicer: '切片',
    modelList: '模型列表',
    loadedModel: '已加载模型',
    addModel: '添加模型',
    modelSink: '模型下沉',
    center: '居中',
    reset: '重置',
    actual: '实际',
    percentage: '百分比',
    lockRatio: '锁定比例',
    scaleToFit: '缩放以适应',
    quantity: '数量',
    apply: '应用',
    modelLoading: '模型加载中',
    autoLayoutInPro: '自动布局中',
    autoLayoutComp: '自动布局完成'
  },
  slicerPars: {
    title: '树脂-切片配置',
    allPars: '全部参数',
    configFile: '配置文件',
    save: '保存',
    printSet: {
      title: '打印设置',
      resinType: {
        label: '树脂类型',
        value: '打印所用的树脂材料类型。'
      }, 
      layThick: {
        label: '层厚',
        value: '层厚范围在0.1～0.025mm。'
      },
      singLayExpTime: '单层曝光时间',
      bottomLayExpTime: '底层曝光时间',
      expDelay: '曝光延迟',
      bottomLayExpLay: '底层曝光层数',
      bottomLayExpTranLay: '底层曝光过渡层数',
      liftHeight1: '抬升高度1',
      lifgSpeed1: '抬升速度1',
      liftHeight2: '抬升高度2',
      lifgSpeed2: '抬升速度2',
      lowerSpeed: '下降速度',
      zAxLiftAfterPrint: '打印完Z轴抬升'
    },
    highSet: {
      modelLiftHeight: '模型抬升高度',
      supportType: '支撑类型',
      title: '高级设置',
      innerDiaComp: '内径补偿',
      outDiaComp: '外径补偿',
      xAxisScaleComp: 'X轴缩放补偿',
      yAxisScaleComp: 'Y轴缩放补偿',
      zAxisScaleComp: 'Z轴缩放补偿',
      antial: {
        label: '抗锯齿',
        value: '等级为“1”时不开启抗锯齿。'
      },
      autoSup: '自动支撑',
      antDisAtLevel1: '等级为“1”时不开启抗锯齿',
      grayLevel: '灰度级别',
      imgBlurPx: '图像模糊像素',
      supDensity: '支撑密度',
      addBase: '添加底座',
      open: '开启',
      close: '关闭'
    },
    saveFailTip: '保存失败,您的切片参数不能为空或者您的配置文件名字有冲突',
    saveNameConflict: '配置文件名字不能为空',
    newConfigSaveSuc: '新的配置保存成功',
    updateSuc: '更新成功',
    updateFail: '更新失败'
  },
  machineSelect: {
    title: '选择机型',
    addMachine: '添加机型',
    commonModels: '常用机型',
    size: '尺寸',
    resolution: '分辨率',
    define: {
      label: '自定义',
      value: '若使用非章鱼核官方机型，可选择添加自定义机型。'
    }
  },
  addMachine: {
    title: '新增自定义机型',
    machineName: '机型名称',
    placeholder: '请输入15字数以内的机型名称',
    platSize: '平台尺寸',
    resolution: '分辨率',
    addMachinePic: '添加机型图片',
    confirm: '确定',
    msg1: '自定义机型名称不能为空',
    msg2: 'X轴不能为空',
    msg3: 'Y轴不能为空',
    msg4: 'Z轴不能为空',
    msg5: '水平分辨率不能为空',
    msg6: '垂直分辨率不能为空',
    msg7: '请添加机型图片',
    picSizeLimit: '上传图片大小不能超过 5MB!',
    updateSuc: '更新成功',
    updateFail: '更新失败',
    truPicFormat: '请上传正确的图片格式',
    delSuc: '删除成功',
    delFail: '删除失败',
    addSuc: '添加成功',
    addFail: '添加失败',
  },
  operateMachine: {
    title: '编辑自定义机型',
    del: '删除',
    machineName: '机型名称',
    placeholder: '请输入15字数以内的机型名称',
    platSize: '平台尺寸',
    resolution: '分辨率',
    machinePic: '机型图片',
    replacePic: '点击替换图片',
    save: '保存',
    isDelMachine: '是否删除此机型',
    cancel: '取消',
    msg1: '自定义机型名称不能为空',
    msg2: 'X轴不能为空',
    msg3: 'Y轴不能为空',
    msg4: 'Z轴不能为空',
    msg5: '水平分辨率不能为空',
    msg6: '垂直分辨率不能为空',
    msg7: '请添加机型图片',
    picSizeLimit: '上传图片大小不能超过 5MB!',
    updateSuc: '更新成功',
    updateFail: '更新失败',
    truPicFormat: '请上传正确的图片格式',
    delSuc: '删除成功',
    delFail: '删除失败',
    addSuc: '添加成功',
    addFail: '添加失败',
    saveSuc: '保存成功',
    
  },
  selectConfigFile: {
    title: '选择配置文件',
    defaultConfig: '默认配置',
    layThick: '层厚',
    defineConfig: '自定义配置',
    allPars: '全部参数',
    printSet: {
      title: '打印设置',
      resinType: {
        label: '树脂类型',
        value: '打印所用的树脂材料类型。'
      }, 
      layThick: {
        label: '层厚',
        value: '层厚范围在0.1～0.025mm。'
      },
      singLayExpTime: '单层曝光时间',
      bottomLayExpTime: '底层曝光时间',
      expDelay: '曝光延迟',
      bottomLayExpLay: '底层曝光层数',
      bottomLayExpTranLay: '底层曝光过渡层数',
      liftHeight1: '抬升高度1',
      lifgSpeed1: '抬升速度1',
      liftHeight2: '抬升高度2',
      lifgSpeed2: '抬升速度2',
      lowerSpeed: '下降速度',
      zAxLiftAfterPrint: '打印完Z轴抬升'
    },
    highSet: {
      title: '高级设置',
      innerDiaComp: '内径补偿',
      outDiaComp: '外径补偿',
      xAxisScaleComp: 'X轴缩放补偿',
      yAxisScaleComp: 'Y轴缩放补偿',
      zAxisScaleComp: 'Z轴缩放补偿',
      antial: {
        label: '抗锯齿',
        value: '等级为“1”时不开启抗锯齿。'
      },
      autoSup: '自动支撑',
      antDisAtLevel1: '等级为“1”时不开启抗锯齿',
      grayLevel: '灰度级别',
      imgBlurPx: '图像模糊像素',
      supDensity: '支撑密度',
      addBase: '添加底座',
      open: '开启',
      close: '关闭'
    },
    delSuc: '删除成功',
    delFail: '删除失败'
  },
  delDialog: {
    tip: '提示',
    txt: '是否删除该配置参数？删除将不可恢复',
    confirm: '确定',
    cancel: '取消'
  },
  outOfBoundDialog: {
    tip: '提示',
    txt: '模型已超出打印机边界,请调整模型的位置或尺寸。(模型添加支撑将有可能超出打印边界)',
    know: '已知道'
  },
  adaptModelDialog: {
    tip: '提示',
    txt: '存在着模型过大或过小,是否调整到合适大小?',
    confirm: '确定',
    cancel: '取消'
  },
  addModelDialog: {
    modelList: {
      label: '模型列表',
      value: '拖动模型进入模型列表即可添加模型'
    },
    modelBase: '模型库',
    searchModel: '搜索模型',
    noResults: '暂无搜索结果'
  },
  excludeModelDialog: {
    tip: '提示',
    txt: '部分模型超出打印机边界，本次切片是否排除这些模型',
    confirm: '是，去切片',
    cancel: '取消'
  },
  delFinalSingleModelDialog: {
    tip: '提示',
    txt: '删除将退出切片预览，是否继续',
    yes: '是',
    no: '否'
  },
  modelHoverDialog: {
    tip: '提示',
    txt: '模型悬空，会导致模型打印错误 是否继续？',
    yes: '是',
    no: '否'
  },
  saveConfigDialog: {
    txt: '配置命名',
    placeholder: '请输入15字以内的配置名称',
    cancel: '取消',
    confirm: '确定'
  },
  saveChangeConfigDialog: {
    txt: '参数已修改，是否保存本次修改',
    cancel: '取消',
    saveAs: '另存为'
  },
  saveUpdateConfigDialog: {
    txt: '参数已修改，是否保存本次修改',
    cancel: '取消',
    confirm: '确定'
  },
  addModel: {
    title: '添加模型',
    placeholder: '请输入模型名称',
    noSearchRes: '暂无搜索结果',
    loading: '加载中',
    noMore: '没有更多了',
    selectPrintModel: '选择打印模型',
    allSelect: '全选',
    confirmSelect: '确认选择'
  }
}
