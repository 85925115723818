<template>
  <div class="userDefinedMachine">
    <i class="el-icon-close" @click="closeDialog"></i>
    <h1 class="title">新增自定义机型</h1>
    <el-form
      ref="form"
      label-position="top"
      :model="machineParame"
      :rules="rules"
      label-width="80px"
    >
      <div class="addForm">
        <div class="left">
          <el-form-item label="机型名称" prop="name">
            <el-input
              v-model="machineParame.name"
              placeholder="请输入自定义机型的名称 "
            ></el-input>
          </el-form-item>
          <div class="printSize">
            <div class="title">平台尺寸</div>
            <el-form-item label="X轴/宽" prop="printSize.x">
              <el-input-number
                :controls="false"
                v-model="machineParame.printSize.x"
                :precision="2"
                :min="50"
                :max="500"
              ></el-input-number>
              <div class="mm">mm</div>
              <div class="add" @click="machineParame.printSize.x++"></div>
              <div class="sub" @click="machineParame.printSize.x--"></div>
            </el-form-item>
            <el-form-item label="Y轴/长" prop="printSize.y">
              <el-input-number
                :controls="false"
                v-model="machineParame.printSize.y"
                :precision="2"
                :min="50"
                :max="500"
              ></el-input-number>
              <div class="mm">mm</div>
              <div class="add" @click="machineParame.printSize.y++"></div>
              <div class="sub" @click="machineParame.printSize.y--"></div>
            </el-form-item>
            <el-form-item label="Z轴/高" prop="printSize.z">
              <el-input-number
                :controls="false"
                v-model="machineParame.printSize.z"
                :precision="2"
                :min="50"
                :max="500"
              ></el-input-number>
              <div class="mm">mm</div>
              <div class="add" @click="machineParame.printSize.z++"></div>
              <div class="sub" @click="machineParame.printSize.z--"></div>
            </el-form-item>
          </div>
          <div class="distinguishability">
            <div class="title">分辨率</div>
            <el-form-item label="水平分辨率" prop="distinguishability.horz">
              <el-input-number
                :controls="false"
                v-model="machineParame.distinguishability.horz"
                :precision="2"
                :min="0"
              ></el-input-number>
              <div class="px">px</div>
              <div
                class="add"
                @click="machineParame.distinguishability.horz++"
              ></div>
              <div
                class="sub"
                @click="machineParame.distinguishability.horz--"
              ></div>
            </el-form-item>
            <el-form-item label="垂直分辨率" prop="distinguishability.vert">
              <el-input-number
                :controls="false"
                v-model="machineParame.distinguishability.vert"
                :precision="2"
                :min="0"
              ></el-input-number>
              <div class="px">px</div>
              <div
                class="add"
                @click="machineParame.distinguishability.vert++"
              ></div>
              <div
                class="sub"
                @click="machineParame.distinguishability.vert--"
              ></div>
            </el-form-item>
          </div>
        </div>
        <div class="right">
          <el-form-item label="添加机型图片" prop="imageUrl">
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="http://zyy.59156.cn/app/common/uploadToServer"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              ref="upload"
              :auto-upload="false"
              :on-change="handleLicensePreview"
            >
              <img
                v-if="machineParame.imageUrl"
                :src="machineParame.imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
      </div>
      <el-button @click="save">保存</el-button>
    </el-form>
  </div>
</template>

<script>
import { addPrinterType, addPrinterPic } from "../api/getInfo.js";
export default {
  name: "UserDefinedMachine",
  data() {
    return {
      machineParame: {
        name: "",
        printSize: {
          x: 0,
          y: 50,
          z: 50,
        },
        distinguishability: {
          horz: 0,
          vert: 0,
        },
        imageUrl: "",
      },
      rules: {
        name: [{ required: true, message: "自定义机型名称不能为空" }],
        "printSize.x": [{ required: true, message: "X轴不能为空" }],
        "printSize.y": [{ required: true, message: "Y轴不能为空" }],
        "printSize.z": [{ required: true, message: "Z轴不能为空" }],
        "distinguishability.horz": [
          { required: true, message: "水平分辨率不能为空" },
        ],
        "distinguishability.vert": [
          { required: true, message: "垂直分辨率不能为空" },
        ],
        imageUrl: [{ required: true, message: "请添加机型图片" }],
      },
      filePic: null,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("closeUserDefineDialog");
    },
    handleAvatarSuccess(res, file) {
      this.machineParame.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.machineParame.imageUrl);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          console.log("addPrinterPic", addPrinterPic);
          const formData = new FormData();
          formData.append("file", this.filePic);
          //上传图片
          const res = await addPrinterPic(formData);
          console.log("resPic", res);
          //上传表单数据
          const res2 = await addPrinterType({
            xAxisSize: this.machineParame.printSize.x,
            yAxisSize: this.machineParame.printSize.y,
            zAxisSize: this.machineParame.printSize.z,
            horizontalResolution: this.machineParame.distinguishability.horz,
            number: this.machineParame.name,
            name: this.machineParame.name,
            verticalResolution: this.machineParame.distinguishability.vert,
            picFileUrl: res.data.url
          });
          if (res2.code === 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            //上传参数并关闭dialog
            this.$emit("closeUserDefineDialog",true);
          } else {
            this.$message.error("添加失败");
          }
          console.log("res2", res2);
          /*  this.machineParame = {
            name: "",
            printSize: {
              x: 0.0,
              y: 0,
              z: 0,
            },
            distinguishability: {
              horz: 0,
              vert: 0,
            },
            imageUrl: "",
          }; */
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleLicensePreview(file) {
      console.log("file", file);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      } else {
        this.filePic = file.raw;
        this.machineParame.imageUrl = URL.createObjectURL(file.raw);
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>