export default {
  slices: '切片',
  support: 'サポート',
  fine: '細',
  medium: '中',
  coarse: '太',
  slicer: {
    move: '移動',
    scale: '拡大/縮小',
    rotate: '回転',
    autoLayout: '自動配置',
    copy: 'コピー',
    slicer: 'スライサー',
    modelList: 'モデルリスト',
    loadedModel: 'モデルが読み込まれました',
    addModel: 'モデルの追加',
    modelSink: 'モデルのシンク',
    center: '中央に配置',
    reset: 'リセット',
    actual: '実際のサイズ',
    percentage: 'パーセンテージ',
    lockRatio: '比率を固定',
    scaleToFit: 'フィットに拡大/縮小',
    quantity: '数量',
    apply: '適用',
    modelLoading: 'モデルの読み込み中',
    autoLayoutInPro: '自動配置中',
    autoLayoutComp: '自動配置完了'
  },
  slicerPars: {
    title: 'レジン-スライス設定',
    allPars: 'すべてのパラメータ',
    configFile: '設定ファイル',
    save: '保存',
    printSet: {
      title: '印刷設定',
      resinType: {
        label: 'レジンの種類',
        value: '使用するレジンの材料タイプ。'
      },
      layThick: {
        label: '層の厚さ',
        value: '層の厚さは0.1～0.025mmの範囲です。'
      },
      singLayExpTime: '単層露光時間',
      bottomLayExpTime: '底層露光時間',
      expDelay: '露光遅延',
      bottomLayExpLay: '底層露光層数',
      bottomLayExpTranLay: '底層露光過渡層数',
      liftHeight1: 'リフト高さ1',
      lifgSpeed1: 'リフト速度1',
      liftHeight2: 'リフト高さ2',
      lifgSpeed2: 'リフト速度2',
      lowerSpeed: '降下速度',
      zAxLiftAfterPrint: 'Z軸の印刷後リフト'
    },
    highSet: {
      modelLiftHeight: 'モデルリフト高さ',
      supportType: 'サポートタイプ',
      title: '高度な設定',
      innerDiaComp: '内径補償',
      outDiaComp: '外径補償',
      xAxisScaleComp: 'X軸のスケール補償',
      yAxisScaleComp: 'Y軸のスケール補償',
      zAxisScaleComp: 'Z軸のスケール補償',
      antial: {
        label: 'アンチエイリアス',
        value: '等級が「1」の場合、アンチエイリアスは無効です。'
      },
      autoSup: '自動サポート',
      antDisAtLevel1: '等級が「1」の場合、アンチエイリアスは無効です',
      grayLevel: 'グレーレベル',
      imgBlurPx: '画像のぼかしピクセル',
      supDensity: 'サポート密度',
      supDensityTip: '数値が大きいほど、追加されるサポートがより密になります',
      addBase: 'ベースの追加',
      open: 'オン',
      close: 'オフ'
    },
    saveFailTip: '保存に失敗しました。スライスパラメータが空であるか、設定ファイルの名前が競合している可能性があります。',
    saveNameConflict: '設定ファイルの名前を指定してください',
    newConfigSaveSuc: '新しい設定が正常に保存されました',
    updateSuc: '更新が成功しました',
    updateFail: '更新に失敗しました'
  },
  machineSelect: {
    title: '機種の選択',
    addMachine: '機種の追加',
    commonModels: '一般的な機種',
    size: 'サイズ',
    resolution: '解像度',
    define: {
      label: 'カスタム',
      value: '章魚コア公式の機種以外を使用する場合は、カスタム機種を追加できます。'
    }
  },
  addMachine: {
    title: 'カスタム機種の追加',
    machineName: '機種名',
    placeholder: '15文字以内で機種名を入力してください',
    platSize: 'プラットフォームサイズ',
    resolution: '解像度',
    addMachinePic: '機種画像を追加',
    confirm: '確定',
    msg1: 'カスタム機種名は空白にできません',
    msg2: 'X軸は空白にできません',
    msg3: 'Y軸は空白にできません',
    msg4: 'Z軸は空白にできません',
    msg5: '水平解像度は空白にできません',
    msg6: '垂直解像度は空白にできません',
    msg7: '機種画像を追加してください',
    picSizeLimit: '画像のサイズは5MB以下にしてください！',
    updateSuc: '更新に成功しました',
    updateFail: '更新に失敗しました',
    truPicFormat: '正しい画像形式をアップロードしてください',
    delSuc: '削除に成功しました',
    delFail: '削除に失敗しました',
    addSuc: '追加に成功しました',
    addFail: '追加に失敗しました',
  },
  operateMachine: {
    title: 'カスタム機種の編集',
    del: '削除',
    machineName: '機種名',
    placeholder: '15文字以内で機種名を入力してください',
    platSize: 'プラットフォームサイズ',
    resolution: '解像度',
    machinePic: '機種画像',
    replacePic: '画像を置き換えるにはクリック',
    save: '保存',
    isDelMachine: 'この機種を削除しますか',
    cancel: 'キャンセル',
    msg1: 'カスタム機種名は空白にできません',
    msg2: 'X軸は空白にできません',
    msg3: 'Y軸は空白にできません',
    msg4: 'Z軸は空白にできません',
    msg5: '水平解像度は空白にできません',
    msg6: '垂直解像度は空白にできません',
    msg7: '機種画像を追加してください',
    picSizeLimit: '画像のサイズは5MB以下にしてください！',
    updateSuc: '更新に成功しました',
    updateFail: '更新に失敗しました',
    truPicFormat: '正しい画像形式をアップロードしてください',
    delSuc: '削除に成功しました',
    delFail: '削除に失敗しました',
    addSuc: '追加に成功しました',
    addFail: '追加に失敗しました',
    saveSuc: '保存に成功しました',
  },
  selectConfigFile: {
    title: '設定ファイルの選択',
    defaultConfig: 'デフォルト設定',
    layThick: 'レイヤーの厚さ',
    defineConfig: 'カスタム設定',
    allPars: '全てのパラメータ',
    printSet: {
      title: '印刷設定',
      resinType: {
        label: 'レジンの種類',
        value: '使用するレジン材料の種類。'
      },
      layThick: {
        label: 'レイヤーの厚さ',
        value: 'レイヤーの厚さは0.1～0.025mmの範囲です。'
      },
      singLayExpTime: '単層露光時間',
      bottomLayExpTime: '底層露光時間',
      expDelay: '露光遅延',
      bottomLayExpLay: '底層露光レイヤー数',
      bottomLayExpTranLay: '底層露光過渡レイヤー数',
      liftHeight1: 'リフト高さ1',
      lifgSpeed1: 'リフト速度1',
      liftHeight2: 'リフト高さ2',
      lifgSpeed2: 'リフト速度2',
      lowerSpeed: '下降速度',
      zAxLiftAfterPrint: '印刷完了後のZ軸リフト'
    },
    highSet: {
      title: '高度な設定',
      innerDiaComp: '内径補償',
      outDiaComp: '外径補償',
      xAxisScaleComp: 'X軸のスケール補償',
      yAxisScaleComp: 'Y軸のスケール補償',
      zAxisScaleComp: 'Z軸のスケール補償',
      antial: {
        label: 'アンチエイリアシング',
        value: 'レベルが“1”の場合、アンチエイリアシングは無効です。'
      },
      autoSup: '自動サポート',
      antDisAtLevel1: 'レベルが“1”の場合、アンチエイリアシングは無効です',
      grayLevel: 'グレースケールレベル',
      imgBlurPx: '画像のぼかしピクセル',
      supDensity: 'サポート密度',
      addBase: 'ベースの追加',
      open: 'オン',
      close: 'オフ'
    },
    delSuc: '削除に成功しました',
    delFail: '削除に失敗しました'
  },
  delDialog: {
    tip: 'ヒント',
    txt: 'この設定パラメータを削除しますか？削除すると元に戻せません',
    confirm: '確定',
    cancel: 'キャンセル'
  },
  outOfBoundDialog: {
    tip: 'ヒント',
    txt: 'モデルはプリンターの境界を超えています。モデルの位置またはサイズを調整してください(モデルにサポートを追加すると印刷範囲を超える可能性があります)。',
    know: '了解しました'
  },
  adaptModelDialog: {
    tip: 'ヒント',
    txt: 'モデルが大きすぎるか小さすぎる可能性があります。適切なサイズに調整しますか？',
    confirm: '確定',
    cancel: 'キャンセル'
  },
  addModelDialog: {
    modelList: {
      label: 'モデルリスト',
      value: 'モデルをリストにドラッグして追加できます'
    },
    modelBase: 'モデルライブラリ',
    searchModel: 'モデルの検索',
    noResults: '検索結果はありません'
  },
  excludeModelDialog: {
    tip: 'ヒント',
    txt: '一部のモデルがプリンターの境界を超えています。今回のスライスでこれらのモデルを除外しますか',
    confirm: 'はい、スライスに進む',
    cancel: 'キャンセル'
  },
  delFinalSingleModelDialog: {
    tip: 'ヒント',
    txt: '削除するとスライスプレビューが終了します。続行しますか',
    yes: 'はい',
    no: 'いいえ'
  },
  modelHoverDialog: {
    tip: 'ヒント',
    txt: 'モデルが浮いていると、モデルの印刷エラーが発生する可能性があります。続行しますか？',
    yes: 'はい',
    no: 'いいえ'
  },
  saveConfigDialog: {
    txt: '設定の名前を入力',
    placeholder: '15文字以内の設定名を入力してください',
    cancel: 'キャンセル',
    confirm: '確定'
  },
  saveChangeConfigDialog: {
    txt: 'パラメータが変更されました。この変更を保存しますか',
    cancel: 'キャンセル',
    saveAs: '名前をつけて保存'
  },
  saveUpdateConfigDialog: {
    txt: 'パラメータが変更されました。この変更を保存しますか',
    cancel: 'キャンセル',
    confirm: '確定'
  },
  addModel: {
    title: 'モデルの追加',
    placeholder: 'モデル名を入力してください',
    noSearchRes: '検索結果はありません',
    loading: '読み込み中',
    noMore: 'これ以上ありません',
    selectPrintModel: '印刷モデルの選択',
    allSelect: '全て選択',
    confirmSelect: '選択を確認'
  }

}
