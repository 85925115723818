<template>
  <div
    ref="slicerContainer"
    class="slicer-container"
    v-loading="loadingCircle"
    :element-loading-text="$t('slicer.modelLoading')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="mask-packLayout" v-if="isPackLayout">
      <span class="txt">{{ $t("slicer.autoLayoutInPro") }}...</span>
    </div>
    <!-- <div class="mask" v-if="loading">
      <el-progress type="circle" :percentage="percentage"></el-progress>
    </div> -->
    <div class="top-nav">
      <i class="el-icon-arrow-left" @click="exitSlicer"></i>
      <div class="modelList" @click="showModelList">
        <i class="icon-a-moxingliebiao1 iconfont"></i>
        <span>{{ $t("slicer.modelList") }}</span>
      </div>
    </div>
    <div class="controls" v-if="controlHidden">
      <ul>
        <li
          v-for="(control, index) in controls"
          :key="index"
          @click="operateModel(control)"
        >
          <i :class="control.class"></i>
          <span>{{ control.name }}</span>
        </li>
      </ul>
    </div>

    <div class="controls-operate">
      <div class="move" v-if="controls[0].isShow">
        <div class="top">
          <span class="name">{{ $t("slicer.move") }}</span>
          <i
            class="el-icon-close"
            @click="
              controls[0].isShow = false;
              controlHidden = true;
            "
          ></i>
        </div>
        <div class="operate">
          <el-input-number
            v-model="copyPosition.x"
            @change="handlePositionChange('x', $event)"
            :precision="2"
            label="描述文字"
          ></el-input-number>
          <el-input-number
            v-model="copyPosition.y"
            @change="handlePositionChange('y', $event)"
            :precision="2"
            label="描述文字"
          ></el-input-number>
          <el-input-number
            v-model="copyPosition.z"
            @change="handlePositionChange('z', $event)"
            :precision="2"
            label="描述文字"
          ></el-input-number>
        </div>
        <div class="axes">
          <span class="x">X (mm)</span>
          <span class="y">Y (mm)</span>
          <span class="z">Z (mm)</span>
        </div>
        <div class="btns">
          <div class="sink" @click="moveOperate('sink')">
            {{ $t("slicer.modelSink") }}
          </div>
          <div class="center" @click="moveOperate('center')">
            {{ $t("slicer.center") }}
          </div>
          <div class="reset" @click="moveOperate('reset')">
            {{ $t("slicer.reset") }}
          </div>
        </div>
      </div>
      <div class="scale" v-if="controls[1].isShow">
        <div class="top">
          <span class="name">{{ $t("slicer.scale") }}</span>
          <i
            class="el-icon-close"
            @click="
              controls[1].isShow = false;
              controlHidden = true;
            "
          ></i>
        </div>
        <div class="scale-control-btns">
          <div
            class="real"
            @click="toggleScaleSizeStyle(true)"
            :style="{
              background: scaleControls.isShowReal ? '#fff' : '#68696D',
              color: scaleControls.isShowReal ? '#111' : '#fff',
            }"
          >
            {{ $t("slicer.actual") }}
          </div>
          <div
            class="perct"
            @click="toggleScaleSizeStyle(false)"
            :style="{
              background: !scaleControls.isShowReal ? '#fff' : '#68696D',
              color: !scaleControls.isShowReal ? '#111' : '#fff',
            }"
          >
            {{ $t("slicer.percentage") }}
          </div>
        </div>
        <div class="locking-ratio">
          <span class="name">{{ $t("slicer.lockRatio") }}</span>
          <el-switch
            v-model="switchControls.scale"
            active-color="#626267"
            inactive-color="#fff"
            :class="{ round: switchControls.scale }"
          >
          </el-switch>
        </div>
        <div class="scale-control" v-if="scaleControls.isShowReal">
          <div class="operate">
            <el-input-number
              v-model="scale.size.x"
              @change="handleScaleChange('x', $event)"
              :precision="2"
              label="描述文字"
            ></el-input-number>
            <el-input-number
              v-model="scale.size.y"
              @change="handleScaleChange('y', $event)"
              :precision="2"
              label="描述文字"
            ></el-input-number>
            <el-input-number
              v-model="scale.size.z"
              @change="handleScaleChange('z', $event)"
              :precision="2"
              label="描述文字"
            ></el-input-number>
          </div>
          <div class="axes">
            <span class="x">X (mm)</span>
            <span class="y">Y (mm)</span>
            <span class="z">Z (mm)</span>
          </div>
        </div>
        <div class="scale-control" v-else>
          <div class="operate">
            <el-input-number
              v-model="scale.per.x"
              @change="handleScaleChange('x', $event, '%')"
              :precision="2"
              label="描述文字"
            ></el-input-number>
            <el-input-number
              v-model="scale.per.y"
              @change="handleScaleChange('y', $event, '%')"
              :precision="2"
              label="描述文字"
            ></el-input-number>
            <el-input-number
              v-model="scale.per.z"
              @change="handleScaleChange('z', $event, '%')"
              :precision="2"
              label="描述文字"
            ></el-input-number>
          </div>
          <div class="axes">
            <span class="x">X (%)</span>
            <span class="y">Y (%)</span>
            <span class="z">Z (%)</span>
          </div>
        </div>
        <div class="btns">
          <div class="reset" @click="scaleOperate('reset')">
            {{ $t("slicer.reset") }}
          </div>
        </div>
      </div>
      <div class="rotate" v-if="controls[2].isShow">
        <div class="top">
          <span class="name">{{ $t("slicer.rotate") }}</span>
          <i
            class="el-icon-close"
            @click="
              controls[2].isShow = false;
              controlHidden = true;
            "
          ></i>
        </div>
        <div class="axes">
          <div class="x">
            <div class="label">X</div>
            <div class="value">
              <!-- <div class="angle">&deg;</div> -->
              <el-input-number
                v-model="spin.x"
                @change="handleRotateChange('x', $event)"
                :precision="2"
                label="描述文字"
              ></el-input-number>
              <div class="add45" @click="handleRotateChange('x', 45, '+')">
                +45&deg;
              </div>
              <div class="sub45" @click="handleRotateChange('x', 45, '-')">
                -45&deg;
              </div>
            </div>
          </div>
          <div class="y">
            <div class="label">Y</div>
            <div class="value">
              <!-- <div class="angle">&deg;</div> -->
              <el-input-number
                v-model="spin.y"
                @change="handleRotateChange('y', $event)"
                :precision="2"
                label="描述文字"
              ></el-input-number>
              <div class="add45" @click="handleRotateChange('y', 45, '+')">
                +45&deg;
              </div>
              <div class="sub45" @click="handleRotateChange('y', 45, '-')">
                -45&deg;
              </div>
            </div>
          </div>
          <div class="z">
            <div class="label">Z</div>
            <div class="value">
              <!-- <div class="angle">&deg;</div> -->
              <el-input-number
                v-model="spin.z"
                @change="handleRotateChange('z', $event)"
                :precision="2"
                label="描述文字"
              ></el-input-number>
              <div class="add45" @click="handleRotateChange('z', 45, '+')">
                +45&deg;
              </div>
              <div class="sub45" @click="handleRotateChange('z', 45, '-')">
                -45&deg;
              </div>
            </div>
          </div>
        </div>
        <div class="reset" @click="resetRotate">{{ $t("slicer.reset") }}</div>
      </div>
      <div class="copy" v-if="controls[4].isShow">
        <div class="top">
          <span class="name">{{ $t("slicer.copy") }}</span>
          <i
            class="el-icon-close"
            @click="
              controls[4].isShow = false;
              controlHidden = true;
            "
          ></i>
        </div>
        <div class="count">
          <div class="label">{{ $t("slicer.quantity") }}</div>
          <div class="value">
            <el-input-number
              v-model="cloneNumber"
              label="描述文字"
              :min="1"
              :max="9"
            ></el-input-number>
          </div>
        </div>
        <div class="apply" @click="cloneModel">{{ $t("slicer.apply") }}</div>
      </div>
    </div>

    <div class="slicer-parmars" ref="foldParmars" v-if="controlHidden">
      <div class="params">
        <ul>
          <li @click="machinesDialogVisible = true">
            <i class="icon-a-jixingxuanze11 iconfont"></i>
            <span>{{ machineParmars.name }}</span>
          </li>
          <li @click="slicerParmarsDialogVisible = true">
            <i class="icon-a-shuzhixuanze1 iconfont"></i>
            <span>{{ configuration?.resinType }}</span>
          </li>
          <li @click="slicerParmarsDialogVisible = true">
            <i class="iconfont icon-qiepianpeizhi"></i>
            <span>{{ configuration?.layerThickness }}mm</span>
          </li>
          <li @click="slicerParmarsDialogVisible = true">
            {{ $t("support") }}
          </li>
        </ul>
      </div>
      <div class="fold" @click="foldParams()" ref="fold">
        <i class="el-icon-arrow-left" v-if="!isFold"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div
      class="slicer-btn"
      v-if="!isShowModelList && controlHidden && isShowSlicerBtn"
      @click="slicer"
    >
      <i class="iconfont icon-a-qiepianpeizhi4"></i>
      <span>{{ $t("slicer.slicer") }}</span>
    </div>
    <div class="canvasDom" ref="canvasDom"></div>
    <div class="modelList-control" v-show="isShowModelList">
      <div class="top">
        <div class="left">
          <span class="name">{{ $t("slicer.modelList") }}</span>
          <span class="all"
            >{{ $t("slicer.loadedModel") }} {{ this.objects.length }}</span
          >
        </div>
        <i
          class="el-icon-close"
          @click="
            isShowModelList = false;
            controlHidden = true;
          "
        ></i>
      </div>
      <ul>
        <li
          v-for="(obj, index) in objects"
          :key="index"
          :style="{
            background: obj.isSelected ? '#868585' : 'rgba(0,0,0,0)',
          }"
          @click="selectModel(index)"
        >
          <div class="icon">
            <span v-if="obj.isShow" @click.stop="showModel(obj)"
              ><i
                class="iconfont icon-bxs-show"
                :style="{
                  color: obj.isSelected ? '#fff' : '#ccc',
                }"
              ></i
            ></span>
            <span v-else @click.stop="hiddenModel(obj)"
              ><i
                class="iconfont icon-hideinvisiblehidden"
                :style="{
                  color: obj.isSelected ? '#fff' : '#ccc',
                }"
              ></i
            ></span>
          </div>
          <div
            class="pic"
            :style="{
              background: obj.isSelected ? '#fff' : 'rgba(224,222,227,0.8)',
            }"
          >
            <img :src="obj.modelInfo.pathThumb" alt="" />
          </div>
          <span
            class="name"
            :style="{
              color: obj.isSelected ? '#fff' : '#ccc',
            }"
            >{{ obj.modelInfo.name }}</span
          >
          <i
            class="el-icon-delete"
            :style="{
              color: obj.isSelected ? '#fff' : '#ccc',
            }"
            @click.stop="delModel(index)"
          ></i>
        </li>
      </ul>
      <div class="addModel" @click="isShowAddModel = true">
        + {{ $t("slicer.addModel") }}
      </div>
    </div>
    <Machines
      @closeMachineDialog="closeMachineDialog"
      :machineId="machineId"
      :machinesDialogVisible="machinesDialogVisible"
      v-if="machinesDialogVisible"
      @reSelectMachine="reSelectMachine"
    >
    </Machines>
    <SlicerParmars
      @updateSeleectConfId="
        activeConfigId = $event;
        updateConfigFile();
      "
      @closeParmarsDialog="closeParmarsDialog"
      :activeConfigId="activeConfigId"
      :machineParmars="machineParmars"
      v-if="slicerParmarsDialogVisible"
      @updateConfigFile="updateConfigFile"
    />
    <AddModel
      @closeAddModelDialog="closeAddModelDialog"
      v-if="isShowAddModel"
    />
    <div
      class="dialogs"
      v-if="
        isShowExcludeModelDialog ||
        isShowModelBeyondBorderDialog ||
        delFinalSingleModelDialog ||
        exitSlicerDialog ||
        modelHoverDialog
      "
    >
      <!-- 模型超出边界弹窗 -->
      <div class="modelBeyondBorderDialog" v-if="isShowModelBeyondBorderDialog">
        <div class="txt">{{ $t("outOfBoundDialog.txt") }}</div>
        <div class="knowed" @click="isShowModelBeyondBorderDialog = false">
          {{ $t("outOfBoundDialog.know") }}
        </div>
      </div>
      <!-- 模型过大或过小弹窗 -->
      <!-- <div class="modelSizeDialog" v-if="isShowModelSizeDialog">
        <div class="txt">存在着模型过大或过小,是否调整到合适大小?</div>
        <div class="btns">
          <div class="confirm">确定</div>
          <div class="cancel">取消</div>
        </div>
      </div> -->
      <!-- 排除全部超出模型弹窗 -->
      <div
        class="excludeModelDialog"
        ref="excludeModelDialog"
        v-if="isShowExcludeModelDialog"
      >
        <div class="txt">{{ $t("excludeModelDialog.txt") }}</div>
        <div class="btns">
          <div
            class="confirm"
            id="confirmButtonisShowExcludeModelDialog"
            @click="excludeModelSlicer"
          >
            {{ $t("excludeModelDialog.confirm") }}
          </div>
          <div class="cancel" id="closeButtonisShowExcludeModelDialog">
            {{ $t("excludeModelDialog.cancel") }}
          </div>
        </div>
      </div>
      <!-- 删除只剩一个模型提示弹窗 -->
      <div class="delFinalSingleModelDialog" v-if="delFinalSingleModelDialog">
        <div class="txt">{{ $t("delFinalSingleModelDialog.txt") }}</div>
        <div class="btns">
          <div class="confirm" @click="back()">
            {{ $t("delFinalSingleModelDialog.yes") }}
          </div>
          <div class="cancel" @click="delFinalSingleModelDialog = false">
            {{ $t("delFinalSingleModelDialog.no") }}
          </div>
        </div>
      </div>
      <div class="exitSlicerDialog" v-if="exitSlicerDialog">
        <div class="txt">是否退出切片页面？</div>
        <div class="btns">
          <div class="cancel" @click="exitSlicerDialog = false">否</div>
          <div class="confirm" @click="back()">是</div>
        </div>
      </div>
      <div class="modelHoverDialog" v-if="modelHoverDialog">
        <div class="txt">{{ $t("modelHoverDialog.txt") }}</div>
        <div class="btns">
          <div class="cancel" ref="modelHoverCancel">
            {{ $t("modelHoverDialog.no") }}
          </div>
          <div class="confirm" ref="modelHoverConfirm">
            {{ $t("modelHoverDialog.yes") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STLLoader1 } from "../Load/PLALoader";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import SetPrinter from "./setPrinter.vue";
// import Machines from "./machines/index.vue";
const Machines = () => import("./machines/index.vue");
const SlicerParmars = () => import("./slicerParmars.vue");
const AddModel = () => import("./addModel.vue");
// import SlicerParmars from "./slicerParmars.vue";
// import AddModel from "./addModel.vue";
import VueUploadComponent from "vue-upload-component";
import { uuid } from "vue-uuid";
import {
  getCurrentUserModelFileList,
  getCurrentModelList,
  getJumpModelFileInfo,
  searchModelsByName,
  uploadSlicePic,
  slice,
  getUserPrintInfo,
  getRecommendModelList,
  getModelFileListByModelID,
  getModelByPath,
} from "../api/getInfo";
export default {
  name: "Slicer",
  components: {
    Machines,
    SlicerParmars,
    AddModel,
  },
  data() {
    return {
      message: null,
      isPackLayout: false,
      loadingCircle: true,
      isShowSlicerBtn: true,
      loadTime: null,
      totalSize: 0,
      loading: true,
      percentage: 0,
      loadIntervalId: null,
      exitSlicerDialog: false,
      //切片时有模型悬空弹窗
      modelHoverDialog: false,
      //删除只剩一个模型提示弹窗
      delFinalSingleModelDialog: false,
      //排除全部超出模型弹窗
      isShowExcludeModelDialog: false,
      //模型超出边界弹窗
      isShowModelBeyondBorderDialog: false,
      //模型过大或过小弹窗
      isShowModelSizeDialog: false,
      isShowAddModel: false,
      slicerParmarsDialogVisible: false,
      machinesDialogVisible: false,
      //复制数目
      cloneNumber: 1,
      position: { x: 0, y: 0, z: 0 },
      copyPosition: { x: 0, y: 0, z: 0 },
      controlParmars: {
        move: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      scaleControls: {
        isShowReal: true,
        isShowPer: false,
      },
      group: null,
      // 旋转
      spin: {
        x: 0,
        y: 0,
        z: 0,
      },
      //是否开启等比缩放
      switchControls: {
        scale: false,
      },
      //缩放尺寸和比例
      scale: {
        size: {
          x: 0,
          y: 0,
          z: 0,
        },
        per: {
          x: 100,
          y: 100,
          z: 100,
        },
      },
      //功能打开的时候隐藏一些元素
      controlHidden: true,
      num: 0,
      //显示模型列表
      isShowModelList: false,
      //移动、缩放等控制器
      controls: [
        {
          name: this.$t("slicer.move"),
          mode: "translate",
          class: "iconfont icon-yidong",
          isShow: false,
        },
        {
          name: this.$t("slicer.scale"),
          mode: "scale",
          class: "iconfont icon-suofang",
          isShow: false,
        },
        {
          name: this.$t("slicer.rotate"),
          mode: "rotate",
          class: "iconfont icon-xuanzhuan",
          isShow: false,
        },
        {
          name: this.$t("slicer.autoLayout"),
          mode: "",
          class: "iconfont icon-a-zidongbuju1",
          isShow: false,
        },
        {
          name: this.$t("slicer.copy"),
          mode: "",
          class: "iconfont icon-fuzhi",
          isShow: false,
        },
      ],
      //折叠参数面板
      isFold: false,
      //保存第一次跳转过来的模型id和模型文件id数据和语言
      params: null,
      isShowSearchModelList: false,
      searchModelsList: [],
      //初始同一个模型下stl文件的id
      idList: [],
      modelId: 8357376,
      printerSize: {
        x: 163.91,
        y: 102.4,
        z: 180.0,
      },
      color: {
        unChecked: [186 / 255, 186 / 255, 186 / 255],
        checked: [255 / 255, 121 / 255, 125 / 255],
        over: [128 / 255, 0, 1],
      },
      objects: [],
      modelList: [],
      modelMap: new Map(),
      machineId: null,
      machineParmars: {},
      activeConfigId: null,
      configurationList: [],
      configuration: null,
      machines: null,
      axes: null,
      gridHelper: null,
      exterGeometry: null,
    };
  },
  async mounted() {
    this.init();
    this.getJumpModelFileInfo();
    //获取打印机配置
    await this.getUserPrintInfo();
    this.initGridHelper();
    this.initExterGeometry();
    this.initAxes();
  },
  methods: {
    getModlBoxFromObject(selectedModel, isPrecise = false) {
      console.log("selectedModel.rotation", selectedModel.rotation.x);
      let { x, y, z } = selectedModel.rotation;
      if (x === 0 && (y === 0) & (z === 0)) {
        isPrecise = false;
      }
      return new THREE.Box3().setFromObject(selectedModel, isPrecise);
    },
    exitSlicer() {
      // window.fltSliceUp.postMessage("back");
      window.flutter_inappwebview.callHandler("fltSliceUp", "back"); // 返回调用
    },
    excludeModelSlicer() {},
    delModel(index) {
      if (this.objects.length <= 1) {
        this.delFinalSingleModelDialog = true;
      } else {
        const model = this.objects[index];
        const lineSegments = this.modelMap.get(model);
        this.scene.remove(model);
        this.scene.remove(lineSegments);
        this.objects.splice(index, 1);
        if (model === this.transformControls.object) {
          this.transformControls.detach();
          this.transformControls.attach(this.objects[0]);
          this.transformControls.visible = false;
        }
        if (this.message) {
          this.message.close();
        }
        this.message = this.$message({
          type: "success",
          message: "删除成功",
          duration: 1000,
          offset: 50,
        });
      }
    },
    back() {
      if (window.fltSliceUp) {
        // window.fltSliceUp.postMessage("back");
        window.flutter_inappwebview.callHandler("fltSliceUp", "back");
      } else {
        this.$router.back();
      }
    },
    //判断是否有模型超出边界
    async isHasModelByBod() {
      /* console.log("111");
      let index = 0;
      const boxExterGeometry = this.getModlBoxFromObject(
        this.exterGeometry
      );
      let res = {
        isShowExcludeModelDialog: false,
        isShowModelBeyondBorderDialog: false,
      };
      const min2 = boxExterGeometry.min;
      const max2 = boxExterGeometry.max;
      while (index < this.objects.length) {
        const box = this.getModlBoxFromObject(this.objects[index]);
        let min = box.min;
        let max = box.max;
        let min1 = min;
        let max1 = max;
        let { x, y, z } = this.printerSize;
        if (
          min.x >= -x / 2 &&
          min.x <= x / 2 &&
          min.y >= -y / 2 &&
          min.y <= y / 2 &&
          min.z >= 0 &&
          min.z <= z &&
          max.x >= -x / 2 &&
          max.x <= x / 2 &&
          max.y >= -y / 2 &&
          max.y <= y / 2 &&
          max.z >= 0 &&
          max.z <= z
        ) {
          //没有超出
          this.objects[index].userData.isShowExcludeModelDialog = false;
        } else {
          //判断模型是否完全超出
          if (
            min1.x <= max2.x &&
            max1.x >= min2.x &&
            min1.y <= max2.y &&
            max1.y >= min2.y &&
            min1.z <= max2.z &&
            max1.z >= min2.z
          ) {
            console.log("1", min1, max1);
            console.log("2", min2, max2);
            console.log("模型部分超出");
            res.isShowModelBeyondBorderDialog = true;
          } else {
            console.log("模型完全超出");
            this.objects[index].userData.isShowExcludeModelDialog = true;
            res.isShowExcludeModelDialog = true;
          }
        }

        index++;
      }
      //判断是否所有的模型全部都超出
      if (
        this.objects.every((model) => model.userData.isShowExcludeModelDialog)
      ) {
        res.isShowModelBeyondBorderDialog = true;
      } */
      let res = {
        isShowExcludeModelDialog: false,
        isShowModelBeyondBorderDialog: false,
      };
      for (let model of this.objects) {
        var modelBoundingBox = this.getModlBoxFromObject(model, true);
        var limitBox = new THREE.Box3();
        let min = {
          x: -this.printerSize.x / 2,
          y: -this.printerSize.y / 2,
          z: 0,
        };
        let max = {};
        if (this.configuration.autoSupport) {
          max = {
            x: this.printerSize.x / 2,
            y: this.printerSize.y / 2,
            z:
              this.printerSize.z -
              this.configuration.modelLiftingHeight,
          };
        } else {
          max = {
            x: this.printerSize.x / 2,
            y: this.printerSize.y / 2,
            z: this.printerSize.z,
          };
        }
        
        limitBox.set(min, max);
        const copyBox = new THREE.Box3();
        copyBox.copy(modelBoundingBox);
        const resBox = copyBox.intersect(limitBox);
        if (resBox.equals(modelBoundingBox)) {
          //模型没有超出
          console.log("模型没有超出");
          model.userData.isShowExcludeModelDialog = false;
        } else {
          //判断模型是部分超出还是全部超出
          if (resBox.max.x === -Infinity && resBox.min.x === Infinity) {
            //模型全部超出
            console.log("模型全部超出");
            model.userData.isShowExcludeModelDialog = true;
            res.isShowExcludeModelDialog = true;
          } else {
            //模型部分超出
            console.log("模型部分超出");
            res.isShowModelBeyondBorderDialog = true;
          }
        }
        console.log("modelBoundingBox", modelBoundingBox);
        console.log("resBox", resBox);
        console.log(
          "modelBoundingBox.intersects(limitBox)",
          resBox.equals(modelBoundingBox)
        );
      }
      //判断是否所有的模型全部都超出
      if (
        this.objects.every((model) => model.userData.isShowExcludeModelDialog)
      ) {
        res.isShowModelBeyondBorderDialog = true;
      }
      return res;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = window.atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    async slicer() {
      let res = await this.isHasModelByBod();
      console.log("res", res);
      if (res.isShowModelBeyondBorderDialog) {
        return (this.isShowModelBeyondBorderDialog = true);
      } else if (res.isShowExcludeModelDialog) {
        this.isShowExcludeModelDialog = true;
        const isSlice = await new Promise((resolve) => {
          this.$nextTick(() => {
            const excludeModelDialog = this.$refs.excludeModelDialog;
            console.log("excludeModelDialog", excludeModelDialog);
            if (excludeModelDialog) {
              excludeModelDialog.addEventListener("click", (e) => {
                console.log("e", e);
                //用户点击是，去切片
                if (e.target.id === "confirmButtonisShowExcludeModelDialog") {
                  this.isShowExcludeModelDialog = false;
                  resolve(true);
                } else if (
                  e.target.id === "closeButtonisShowExcludeModelDialog"
                ) {
                  this.isShowExcludeModelDialog = false;
                  resolve(false);
                }
              });
            }
          });
        });
        if (!isSlice) return;
      }

      let modelHover = false;
      /*  //判断是否有模型悬空
       modelHover = this.objects.some((model) => {
        const box = this.getModlBoxFromObject(model, true);
        console.log("box", box);
        return box.min.z !== 0;
      }); */
      if (!this.configuration.autoSupport) {
        this.configuration.isAutoSupport = 0;
        //判断是否有模型悬空
        modelHover = this.objects.some((model) => {
          const box = this.getModlBoxFromObject(model, true);
          console.log("box", box);
          return box.min.z !== 0;
        });
      } else {
        modelHover = false;
        this.configuration.isAutoSupport = 1;
        if (this.configuration.addBase) {
          this.configuration.isAddBase = 1;
        } else {
          this.configuration.isAddBase = 0;
        }
      }
      if (modelHover) {
        this.modelHoverDialog = true;
        let isSlicer = await new Promise((resolve) => {
          this.$nextTick(() => {
            this.$refs.modelHoverConfirm.addEventListener("click", () => {
              this.modelHoverDialog = false;
              resolve(true);
            });
            this.$refs.modelHoverCancel.addEventListener("click", () => {
              this.modelHoverDialog = false;
              resolve(false);
            });
          });
        });
        if (!isSlicer) {
          return;
        }
      }

      //在拍照前将除网格内的模型其它物体进行隐藏
      this.objects.forEach((model) => {
        if (model.userData.isShowExcludeModelDialog) {
          model.visible = false;
        }
        //如果是选中状态取消
        if (model.isSelected) {
          this.cancelSelectModels();
        }
      });
      console.log("this", this);
      this.exterGeometry.visible = false;
      this.gridHelper.visible = false;
      this.axes.visible = false;
      this.orbitControls.reset();
      this.isShowSlicerBtn = false;
      setTimeout(async () => {
        //切片时拍照获得预览照
        const canvas = document.getElementsByTagName("canvas")[0];
        const image = canvas.toDataURL("image/png");
        console.log("image", image);
        const imgFile = this.base64ImgtoFile(image, uuid.v1());
        // console.log('imgFile',imgFile);
        // imgFile.name = uuid.v1()
        const formData = new FormData();
        // console.log('uuid',uuid.v1());
        formData.append("file", imgFile);
        const uploadPicRes = await uploadSlicePic(formData);
        let picId = uploadPicRes.data.id;
        console.log("params", this.parmarters);
        const params = {
          fileList: [],
          previewPicId: picId,
          printerTypeId: this.machineId,
          printerConfiguration: this.configuration,
          // lang: this.params.lang,
          lang: "",
        };
        delete params.printerConfiguration.printerTypeName;
        for (let model of this.objects) {
          if (!model.userData.isShowExcludeModelDialog) {
            params.fileList.push({
              fileId: model.modelInfo.id,
              matrix: model.matrixWorld.transpose().elements,
              modelId: model.modelInfo.modelId,
            });
          }
        }
        // this.websock.send(JSON.stringify(params));
        //调取切片上传接口
        const res = await slice(params);
        // 切片失败
        if (res.code !== 200) {
          if (this.message) {
            this.message.close();
          }
          this.message = this.$message({
            message: "切片失败:" + res.msg,
            type: "error",
          });
          this.objects.forEach((model) => {
            if (model.userData.isShowExcludeModelDialog) {
              model.visible = true;
            }
            if (model.isSelected) {
              this.cancelSelectModels();
            }
          });
          this.exterGeometry.visible = true;
          this.gridHelper.visible = true;
          this.axes.visible = true;
          this.isShowSlicerBtn = true;
          // return window.flutter_inappwebview.callHandler('fltSliceUp', 'back'); // 返回调用
        }else{
          window.flutter_inappwebview.callHandler("fltSliceUp", "slicer"); // 点击切片调用
        }
        // window.fltSliceUp.postMessage("slicer");
      }, 500);
    },
    async closeMachineDialog(id) {
      console.log("id", id);
      console.log("this.machineId", this.machineId);
      this.machinesDialogVisible = false;
      if (id !== this.machineId) {
        this.machineId = id;
        console.log("重新自动布局");
        //需要更新所有机型,不然新增的机型拿不到
        let machine = this.machines.find((machine) => machine.id === id);
        if (!machine) {
          const res = await getUserPrintInfo();
          this.machines = res.rows;
          machine = this.machines.find((machine) => machine.id === id);
        }
        this.machineParmars = machine;
        this.activeConfigId = this.machineParmars.configurationList[0].id;
        this.configuration = this.machineParmars.configurationList[0];
        console.log("machine", machine);
        this.printerSize = {
          x: machine.xaxisSize,
          y: machine.yaxisSize,
          z: machine.zaxisSize,
        };
        this.group.remove(this.gridHelper);
        this.group.remove(this.axes);
        this.group.remove(this.exterGeometry);
        for (let obj of this.objects) {
          obj.material = this.getShaderMaterial();
          obj.isSelected = false;
          const lineSegments = this.modelMap.get(obj);
          lineSegments.visible = false;
          this.transformControls.visible = false;
        }
        this.initGridHelper();
        this.initExterGeometry();
        this.initAxes();
        let size = this.printerSize;
        const radius = Math.sqrt(
          (size.x / 2) * (size.x / 2) +
            (size.y / 2) * (size.y / 2) +
            (size.z / 2) * (size.z / 2)
        );
        if (radius < size.y / 2) {
          radius = size.y / 2;
        }
        this.camera.position.z = this.printerSize.z / 2;
        this.camera.position.y = -radius * 3;
        this.camera.position.x = 0;
        // this.init()
        this.packLayout();
      }
    },
    async closeAddModelDialog(ids, modelId) {
      this.isShowAddModel = false;
      if (ids) {
        this.loadingCircle = true;
        console.log("ids", ids, modelId);
        const res = await getJumpModelFileInfo(ids);
        for (let model of res.rows) {
          model.modelId = modelId;
        }
        const promises = [];
        for (let modelInfo of res.rows) {
          promises.push(
            new Promise(async (resolve, reject) => {
              await this.loadStlModel(
                modelInfo.url,
                modelInfo,
                resolve,
                reject
              );
              this.idList.push(modelInfo.id);
            })
          );
        }
        Promise.all(promises)
          .then(() => {
            console.log("loading", this.loading);
            this.packLayout();
            this.loadingCircle = false;
            // this.loading = false;
          })
          .catch((err) => {
            if (this.message) {
              this.message.close();
            }
            this.message = this.$message({
              showClose: true,
              message: "模型加载失败",
              type: "error",
            });
            this.loadingCircle = false;
          });
      }
    },
    // 关闭参数配置选择
    closeParmarsDialog(pararmsForm) {
      this.slicerParmarsDialogVisible = false;
      console.log("pararmsForm", pararmsForm);
      this.configuration = pararmsForm;
      this.activeConfigId = this.configuration.id;
    },
    async updateConfigFile() {
      const res = await getUserPrintInfo();
      if (res.code === 200) {
        const selectedMachine = res.rows.find(
          (machine) => machine.id === this.machineId
        );
        // this.configurationList = selectedMachine.configurationList
        this.machineParmars = selectedMachine;
      }
    },
    reSelectMachine(machineParmars) {
      this.machinesDialogVisible = false;
      this.machineParmars = machineParmars;
      this.configurationList = machineParmars.configurationList;
      this.machineId = machineParmars.id;
      this.activeConfigId = this.machineParmars.configurationList[0].id;
      this.configuration = this.configurationList[0];
    },
    //获取默认切片机型参数
    async getUserPrintInfo() {
      const res = await getUserPrintInfo();
      const data = res.rows[0];
      this.printerSize = {
        x: data.xaxisSize,
        y: data.yaxisSize,
        z: data.zaxisSize,
      };
      console.log("切片机型参数", this.printerSize);
      let {
        xaxisSize,
        yaxisSize,
        zaxisSize,
        verticalResolution,
        horizontalResolution,
      } = data;
      this.parmarters = {
        xaxisSize,
        yaxisSize,
        zaxisSize,
        verticalResolution,
        horizontalResolution,
        printerTypeId: data.id,
        configuration: data.configurationList[0],
      };
      this.machines = res.rows;
      this.machineId = res.rows[0].id;
      this.machineParmars = { ...res.rows[0] };
      this.activeConfigId = this.machineParmars.configurationList[0].id;
      this.configuration = this.machineParmars.configurationList[0];
      console.log("默认切片机型参数", this.printerSize);
    },
    showModel(obj) {
      obj.isShow = false;
      obj.visible = false;
      // obj.userData.selectable = false;
      const lineSegments = this.modelMap.get(obj);
      lineSegments.visible = false;
      // this.transformControls.visible = false
      console.log();
      this.$forceUpdate();
    },
    hiddenModel(obj) {
      obj.isShow = true;
      obj.visible = true;
      const lineSegments = this.modelMap.get(obj);
      if (obj.isSelected) {
        lineSegments.visible = true;
      }
      this.$forceUpdate();
    },
    //通过模型列表选中模型
    selectModel(index) {
      const model = this.objects[index];
      console.log("position", model.position);
      this.cancelSelectModels();
      model.isSelected = true;
      const size = this.getModelSize(model, true);
      const lineSegments = this.modelMap.get(model);
      lineSegments.visible = true;
      this.transformControls.attach(model);
      model.material.uniforms.color.value.set(...this.color.checked);
      this.getSelectedModelParmars(model);
    },
    //复制模型
    async cloneModel() {
      const selectedModel = this.hintSelectModel();
      if (selectedModel) {
        for (let i = 0; i < this.cloneNumber; i++) {
          const modelLineSegment = this.modelMap.get(selectedModel);
          modelLineSegment.visible = false;
          this.transformControls.visible = false;
          const cloneModel = selectedModel.clone();
          const shaderMaterial = this.getShaderMaterial();
          cloneModel.material = shaderMaterial;
          cloneModel.size = selectedModel.size;
          cloneModel.adaptSize = selectedModel.adaptSize;
          cloneModel.isShow = true;
          cloneModel.visible = true;
          cloneModel.isSelected = false;
          cloneModel.modelInfo = selectedModel.modelInfo;
          this.objects.push(cloneModel);
          //获得模型尺寸
          const size = this.getModelSize(cloneModel, true);
          const lineSegments = this.getBoundingBox(size, cloneModel);
          this.scene.add(lineSegments);
          lineSegments.visible = false;
          this.modelMap.set(cloneModel, lineSegments);

          //把最初第一个模型的最初位置改为布局后的初始位置
          // this.objects[0].initPosition = { ...selectedModel.position };
          // cloneModel.initPosition = { ...cloneModel.position };
          this.scene.add(cloneModel);
        }
        await this.packLayout();
      }
    },

    //重置旋转
    resetRotate() {
      const selectedModel = this.hintSelectModel();
      if (selectedModel) {
        this.spin = {
          x: 0,
          y: 0,
          z: 0,
        };
        selectedModel.rotation.set(0, 0, 0);
        this.modelContactFloor(selectedModel);
      }
    },
    //模型操作时让模型最低点一直贴地
    modelContactFloor(selectedModel) {
      const box = this.getModlBoxFromObject(selectedModel, true);
      selectedModel.position.z -= box.min.z;
      const lineSegments = this.modelMap.get(selectedModel);
      this.scene.remove(lineSegments);
      const size = this.getModelSize(selectedModel, true);
      const newLineSegments = this.getBoundingBox(size, selectedModel);

      this.modelMap.set(selectedModel, newLineSegments);
      this.scene.add(newLineSegments);
      newLineSegments.position.set(
        box.max.x * 0.5 + box.min.x * 0.5,
        box.max.y * 0.5 + box.min.y * 0.5,
        (box.max.z - box.min.z) * 0.5
      );
      //  console.log(aa);
      // var boundingBox = this.getModlBoxFromObject(selectedModel);
    },
    //旋转参数改变
    handleRotateChange(axes, val, flag) {
      if (val === undefined) return;
      let temp = this.hintSelectModel();
      if (!temp) {
        return;
      }
      const selectedModel = this.objects.find((model) => model.isSelected);
      if (flag === "+") {
        selectedModel.rotation[axes] =
          Math.PI * ((this.spin[axes] + val) / 180);
        this.spin[axes] += val;
      } else if (flag === "-") {
        selectedModel.rotation[axes] =
          Math.PI * ((this.spin[axes] - val) / 180);
        this.spin[axes] -= val;
      } else {
        selectedModel.rotation[axes] = (Math.PI * val) / 180;
      }
      this.modelContactFloor(selectedModel);
    },

    getSpinEluerAngel() {
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      console.log("spinSelect", selectedModel);
      if (selectedModel) {
        var euler = new THREE.Euler().setFromQuaternion(
          selectedModel.quaternion,
          "XYZ"
        );
        var degX = THREE.MathUtils.radToDeg(euler.x);
        var degY = THREE.MathUtils.radToDeg(euler.y);
        var degZ = THREE.MathUtils.radToDeg(euler.z);
        return {
          x: degX,
          y: degY,
          z: degZ,
        };
      } else {
        return {
          x: 0,
          y: 0,
          z: 0,
        };
      }
    },
    //缩放参数改变
    handleScaleChange(axes, val, per) {
      console.log("val", val);
      if (val === undefined) return;
      this.hintSelectModel();
      const selectedModel = this.objects.find((model) => model.isSelected);
      console.log("selectedModel", selectedModel);
      // const lineSegments = this.modelMap.get(selectedModel)
      //是百分比还是直接改变
      let { x, y, z } = this.scale.per;
      if (per === "%") {
        if (this.switchControls.scale) {
          selectedModel.scale.set(val / 100, val / 100, val / 100);
          this.scale.per = {
            x: val,
            y: val,
            z: val,
          };
        } else {
          switch (axes) {
            case "x":
              selectedModel.scale.set(val / 100, y / 100, z / 100);
              break;
            case "y":
              selectedModel.scale.set(x / 100, val / 100, z / 100);
              break;
            case "z":
              selectedModel.scale.set(x / 100, y / 100, val / 100);
              break;
          }
        }
      } else {
        if (this.switchControls.scale) {
          let per = val / selectedModel.initSize[axes];
          console.log("per", per);
          selectedModel.scale.set(per, per, per);
          const size = this.getModelSize(selectedModel, true);
          this.scale.size = size;
          this.scale.per = {
            x: per * 100,
            y: per * 100,
            z: per * 100,
          };
        } else {
          let { x, y, z } = selectedModel.scale;
          switch (axes) {
            case "x":
              selectedModel.scale.set(val / selectedModel.initSize[axes], y, z);
              this.scale.per = {
                x: (val / selectedModel.initSize[axes]) * 100,
                y: y * 100,
                z: z * 100,
              };
              break;
            case "y":
              selectedModel.scale.set(x, val / selectedModel.initSize[axes], z);
              this.scale.per = {
                x: x * 100,
                y: (val / selectedModel.initSize[axes]) * 100,
                z: z * 100,
              };
              break;
            case "z":
              selectedModel.scale.set(x, y, val / selectedModel.initSize[axes]);
              this.scale.per = {
                x: x * 100,
                y: y * 100,
                z: (val / selectedModel.initSize[axes]) * 100,
              };
              break;
          }
        }
      }
      // this.getSelectedModelParmars(selectedModel)

      const box = this.getModlBoxFromObject(selectedModel, true);
      selectedModel.position.z -= box.min.z;
      // 获取新的包围盒覆盖原来的
      const lineSegments = this.modelMap.get(selectedModel);
      this.scene.remove(lineSegments);
      console.log("scene", this.scene);
      const newLineSegments = this.getBoundingBox(
        this.getModelSize(selectedModel, true),
        selectedModel
      );
      this.modelMap.set(selectedModel, newLineSegments);
      this.scene.add(newLineSegments);
    },
    scaleOperate(name) {
      const selectedModel = this.hintSelectModel();
      if (selectedModel) {
        switch (name) {
          case "reset":
            let scale = selectedModel.adaptSize.x / selectedModel.initSize.x;
            selectedModel.scale.set(scale, scale, scale);
            this.scale.size = {
              ...selectedModel.adaptSize,
            };
            this.scale.per = {
              x: scale * 100,
              y: scale * 100,
              z: scale * 100,
            };
            this.modelContactFloor(selectedModel);
            break;
        }
      }
    },
    //在缩放中切换实际尺寸和百分比
    toggleScaleSizeStyle(flag) {
      this.scaleControls.isShowReal = flag;
      const selectedModel = this.objects.find((obj) => obj.isSelected);
      if (flag && selectedModel) {
        //获取选中模型实际尺寸
        const size = this.getModelSize(selectedModel, true);
        this.scale.size = size;
      } else if (!flag && selectedModel) {
        this.scale.per = { ...selectedModel.scale };
        for (let key of Object.keys(this.scale.per)) {
          this.scale.per[key] = this.scale.per[key] * 100;
        }
      } else {
        this.scaleReset();
      }
    },

    //如果没有被选中的模型点击操作弹窗要选中模型
    hintSelectModel() {
      const selectedModel = this.objects.find((model) => model.isSelected);
      if (selectedModel) {
        return selectedModel;
      } else {
        if (this.message) {
          this.message.close();
        }
        this.message = this.$message({
          message: "请先选中单个模型",
          type: "warning",
          duration: 2000,
          showClose: true,
          offset: 50,
        });
        return false;
      }
    },
    //模型移动
    handlePositionChange(axes, val) {
      if (val === undefined) return;
      const selectedModel = this.objects.find((model) => model.isSelected);
      if (selectedModel) {
        if (axes !== "z") {
          selectedModel.position[axes] = val;
          const box = this.getModlBoxFromObject(selectedModel, true);
          const lineSegments = this.modelMap.get(selectedModel);
          lineSegments.position.set(
            box.max.x * 0.5 + box.min.x * 0.5,
            box.max.y * 0.5 + box.min.y * 0.5,
            (box.max.z - box.min.z) * 0.5
          );
        } else {
          // selectedModel.position[axes] = 0
          let box = this.getModlBoxFromObject(selectedModel, true);
          selectedModel.position.z = selectedModel.position.z - box.min.z + val;
          box = this.getModlBoxFromObject(selectedModel, true);
          const lineSegments = this.modelMap.get(selectedModel);
          lineSegments.position.set(
            box.max.x * 0.5 + box.min.x * 0.5,
            box.max.y * 0.5 + box.min.y * 0.5,
            box.max.z * 0.5 + box.min.z * 0.5
          );
        }
      } else {
        if (this.message) {
          this.message.close();
        }
        this.message = this.$message({
          message: "请先选中单个模型",
          type: "warning",
          duration: 1000,
          showClose: true,
          offset: 50,
        });
        return;
      }
    },
    //移动下沉、居中、重置
    moveOperate(name) {
      let selectedModel = this.hintSelectModel();
      if (!selectedModel) return;
      let box = this.getModlBoxFromObject(selectedModel, true);
      switch (name) {
        case "sink":
          selectedModel.position.z -= box.min.z;
          this.copyPosition.z = 0;
          const lineSegmentsSink = this.modelMap.get(selectedModel);
          box = this.getModlBoxFromObject(selectedModel, true);
          lineSegmentsSink.position.set(
            box.max.x * 0.5 + box.min.x * 0.5,
            box.max.y * 0.5 + box.min.y * 0.5,
            (box.max.z - box.min.z) * 0.5
          );
          break;
        case "center":
          selectedModel.position.set(
            selectedModel.position.x - box.getCenter(new THREE.Vector3()).x,
            selectedModel.position.y - box.getCenter(new THREE.Vector3()).y,
            selectedModel.position.z - box.min.z
          );
          const lineSegmentsCenter = this.modelMap.get(selectedModel);
          this.modelContactFloor(selectedModel);
          lineSegmentsCenter.position.set(
            selectedModel.position.x - box.getCenter(new THREE.Vector3()).x,
            selectedModel.position.y - box.getCenter(new THREE.Vector3()).y,
            selectedModel.position.z - box.min.z
          );
          this.copyPosition = { x: 0, y: 0, z: 0 };
          break;
        case "reset":
          selectedModel.position.set(
            selectedModel.initPosition.x,
            selectedModel.initPosition.y,
            selectedModel.initPosition.z
          );
          const lineSegments = this.modelMap.get(selectedModel);
          lineSegments.position.set(
            selectedModel.initPosition.x,
            selectedModel.initPosition.y,
            selectedModel.initPosition.z
          );
          this.modelContactFloor(selectedModel);
          this.copyPosition = {
            x: selectedModel.initPosition.x,
            y: selectedModel.initPosition.y,
            z: 0,
          };
          break;
      }
    },
    showModelList() {
      this.isShowModelList = true;
      this.controlHidden = false;
      for (let control of this.controls) {
        control.isShow = false;
      }
    },
    selectDefaultModel(obj) {
      obj.isSelected = true;
      //给模型改变颜色
      setTimeout(() => {
        if (obj.isSelected) {
          obj.material.uniforms.color.value.set(...this.color.checked);
        }
      });
      const lineSegments = this.modelMap.get(obj);
      lineSegments.visible = true;
      this.transformControls.visible = true;
      this.transformControls.attach(obj);
    },
    //获取模型移动、旋转、缩放有模型选中时参数
    getSelectedModelParmars(model) {
      const size = this.getModelSize(model, true);
      //移动
      this.position = model.position;
      const box = this.getModlBoxFromObject(model, true);
      this.copyPosition = { ...this.position };
      this.copyPosition.z = box.min.z;
      //缩放
      this.scale.size = size;
      this.scale.per = {
        x: model.scale.x * 100,
        y: model.scale.y * 100,
        z: model.scale.z * 100,
      };
      //旋转
      this.spin = this.getSpinEluerAngel();
    },
    //点击移动、缩放、旋转~按钮
    operateModel(control) {
      console.log("control", control);
      if (control.name !== this.$t("slicer.autoLayout")) {
        this.controlHidden = false;
        control.isShow = true;
        this.isShowModelList = false;
        const selectedModel = this.objects.find((model) => model.isSelected);
        if (control.mode) {
          this.transformControls.visible = true;
          this.transformControls.setMode(control.mode);
        } else {
          this.transformControls.visible = false;
        }
        if (!selectedModel) {
          this.selectDefaultModel(this.objects[0]);
          this.getSelectedModelParmars(this.objects[0]);
        } else {
        }
      } else {
        this.packLayout();
      }
    },
    foldParams() {
      if (this.isFold) {
        this.$refs.foldParmars.style.left = 0 + "px";
      } else {
        this.$refs.foldParmars.style.left =
          -(this.$refs.fold.offsetLeft + this.$refs.foldParmars.offsetLeft) +
          "px";
      }
      this.isFold = !this.isFold;
    },
    init() {
      this.initScene();
      this.initCamera();
      this.initRenderer();
      this.initOrbitControls();
      this.initTransformControls();
      this.initLight();
      this.animate();
      // 监听浏览器窗口大小变化并更新renderer和camera等属性
      window.addEventListener("resize", () => {
        console.log("resize");
        var viewportWidth =
          window.innerWidth || document.documentElement.clientWidth;
        var viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        this.renderer.setSize(viewportWidth, viewportHeight);
        this.camera.aspect = viewportWidth / viewportHeight;
        this.camera.updateProjectionMatrix();
        // this.renderer.setPixelRatio(window.devicePixelRatio);
      });
    },
    async getJumpModelFileInfo() {
      // this.$message.success('获取跳转信息');
      //在跳转到该页面时拿去模型id和模型文件id数组和语言
      //进行解码
      this.params = JSON.parse(decodeURIComponent(this.$route.query.params));
      localStorage.setItem("token", this.params.token);
      const res = await getJumpModelFileInfo(this.params.modelFileIds);
      for (let model of res.rows) {
        model.modelId = this.params.modelId;
      }
      const promises = [];
      for (let modelInfo of res.rows) {
        promises.push(
          new Promise((resolve, reject) => {
            this.idList.push(modelInfo.id);
            this.totalSize += modelInfo.size;
            // this.$message.success('发送获取模型');
            this.loadStlModel(modelInfo.url, modelInfo, resolve, reject);
            console.log("加载模型");
            /* fetch(modelInfo.url)
              .then((response) => {
                this.loadIntervalId = setInterval(() => {
                  if (this.percentage < 99) {
                    this.percentage += 1;
                  }
                }, 100);
                return response.blob();
              })
              .then((blob) => {
                const blobUrl = URL.createObjectURL(blob);
                // 在此处使用临时URL进行操作，例如显示图像
                this.loadStlModel(blobUrl, modelInfo);
                resolve();
                // 在使用完临时URL后记得释放资源
                // URL.revokeObjectURL(blobUrl);
              })
              .catch((error) => {
                console.error("Failed to fetch the file:", error);
              }); */
          })
        );
      }
      Promise.all(promises)
        .then(() => {
          console.log("loading", this.loading);
          this.packLayout();
          this.loading = false;
          this.loadingCircle = false;
        })
        .catch((err) => {
          if (this.message) {
            this.message.close();
          }
          this.message = this.$message({
            showClose: true,
            message: "模型加载失败",
            type: "error",
          });
          setTimeout(() => {
            window.flutter_inappwebview.callHandler("fltSliceUp", "back"); // 返回调用
          }, 500);
        });
    },
    //初始化场景
    initScene() {
      const scene = new THREE.Scene();
      this.scene = scene;
      scene.position.set(0, 0, 0);
      scene.background = new THREE.Color("#ADAFB6");
      const group = new THREE.Group();
      this.group = group;
      this.scene.add(group);
    },
    //初始化相机
    initCamera() {
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        30000
      );
      this.camera = camera;
      camera.position.y = -240;
      camera.position.z = 80;
      // camera.position.applyAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);
      const target = new THREE.Vector3(0, 0, camera.position.z);
      camera.up = new THREE.Vector3(0, 0, 1);
      camera.lookAt(target);
    },
    //初始化渲染器
    initRenderer() {
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      });
      this.renderer = renderer;
      renderer.sortObjects = false;
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.slicerContainer.appendChild(renderer.domElement);
    },
    // 添加网格地面
    initGridHelper() {
      // #C3C4C9
      const gridHelper = new THREE.GridHelper(
        this.printerSize.x,
        20,
        "#D71518",
        "#C3C4C9"
      );
      this.gridHelper = gridHelper;
      gridHelper.rotation.x = -Math.PI / 2;
      gridHelper.scale.z = this.printerSize.y / this.printerSize.x;
      gridHelper.material.depthTest = false;
      // this.scene.add(gridHelper);
      this.group.add(gridHelper);
    },
    //创建搭建模型外部的几何体
    initExterGeometry() {
      const { x, y, z } = this.printerSize;
      const geometry = new THREE.BoxGeometry(x, y, z);
      // define the wireframe of the cube
      const wireframe = new THREE.EdgesGeometry(geometry);

      // define the material of the wireframe
      const material = new THREE.LineBasicMaterial({
        color: "#fff",
        linewidth: 2,
      });
      // combine the wireframe and material
      const cube = new THREE.LineSegments(wireframe, material);
      cube.position.set(0, 0, this.printerSize.z / 2);
      // this.scene.add(cube);
      this.group.add(cube);
      this.exterGeometry = cube;
    },
    //初始化辅助坐标轴
    initAxes() {
      let min = Math.min(
        this.printerSize.x,
        this.printerSize.y,
        this.printerSize.z
      );
      const axes = new THREE.AxesHelper(min / 3);
      const { x, y, z } = this.printerSize;
      this.axes = axes;
      axes.material.linewidth = 10;
      axes.material.depthTest = false;
      axes.position.set(-x / 2, -y / 2, 0);
      // this.scene.add(axes);
      this.group.add(axes);
    },
    //初始化轨道控制器
    initOrbitControls() {
      const orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      orbitControls.target = new THREE.Vector3(0, 0, this.printerSize.z / 2);
      this.orbitControls = orbitControls;
      orbitControls;
      //启用阻尼
      orbitControls.enableDamping = true;
      orbitControls.dampingFactor = 0.5;
      //是否开启右键拖拽
      orbitControls.enablePan = false;
      orbitControls.update();
    },
    //初始化拖拽控制器
    initTransformControls() {
      // 添加平移控件
      const transformControls = new TransformControls(
        this.camera,
        this.renderer.domElement
      );
      this.transformControls = transformControls;
      transformControls.size = 0.4;
      this.scene.add(transformControls);
      transformControls.addEventListener("dragging-changed", (event) => {
        this.orbitControls.enabled = !event.value;
      });
      //最初的包围盒
      let lineSegments = null;
      let spinlineSegements = null;
      transformControls.addEventListener("mouseUp", (e) => {
        console.log("up");
        this.isShowTransformControls = true;
        const selectedModel = this.objects.find((obj) => obj.isSelected);
        if (selectedModel) {
          const size = this.getModelSize(selectedModel, true);
          const box = this.getModlBoxFromObject(selectedModel, true);
          const lineSegments = this.modelMap.get(selectedModel);
          if (lineSegments) {
            this.scene.remove(lineSegments);
          }
          // selectedModel.position.z -= box.min.z;
          // this.copyPosition.z = 0;
          let newLineSegments = this.getBoundingBox(size, selectedModel);
          this.modelMap.set(selectedModel, newLineSegments);
          this.scene.add(newLineSegments);
          newLineSegments.position.set(
            box.max.x * 0.5 + box.min.x * 0.5,
            box.max.y * 0.5 + box.min.y * 0.5,
            box.max.z * 0.5 + box.min.z * 0.5
          );
          // (box.max.z - box.min.z) * 0.5
        }
      });
      transformControls.addEventListener("objectChange", (e) => {
        console.log("change");
        const selectedModel = this.objects.find((obj) => obj.isSelected);
        if (selectedModel) {
          this.scene.remove(this.modelMap.get(selectedModel));
        }
        //判断处于哪种模式
        switch (transformControls.mode) {
          case "scale":
            if (selectedModel) {
              let size = this.getModelSize(selectedModel);
              if (this.switchControls.scale) {
                // 获取控件各轴线上的缩放值
                var scaleX = transformControls.object.scale.x;
                var scaleY = transformControls.object.scale.y;
                var scaleZ = transformControls.object.scale.z;
                // 计算缩放因子
                var avgScale = (scaleX + scaleY + scaleZ) / 3;
                selectedModel.scale.set(avgScale, avgScale, avgScale);
                size = this.getModelSize(selectedModel);
              }
              this.scale.size = size;
              //获取模型缩放的百分比
              this.scale.per = { ...selectedModel.scale };
              for (let key of Object.keys(this.scale.per)) {
                this.scale.per[key] = this.scale.per[key] * 100;
              }
            }
            break;
          case "rotate":
            if (selectedModel) {
              this.spin = this.getSpinEluerAngel();
            }
            break;
          case "translate":
            if (selectedModel) {
              this.position = selectedModel.position;
              // const box = this.getModlBoxFromObject(selectedModel,true);
              this.copyPosition = { ...this.position };
              this.copyPosition.z = 0;
              // this.copyPosition.z = box.min.z;
            }
        }
        // for (let obj of this.objects) {
        //   if (obj.isSelected) {
        //     const box = this.getModlBoxFromObject(obj, true);
        //     this.modelMap
        //       .get(obj)
        //       .position.set(box.max.x * 0.5 + box.min.x *  0.5,
        // box.max.y * 0.5 + box.min.y *  0.5,
        // (box.max.z - box.min.z) *  0.5,);
        //   }
        // }
      });
    },
    //初始化光照
    initLight() {
      const ambienLight = new THREE.AmbientLight(0xfff, 0.1);
      this.scene.add(ambienLight);
      const light = new THREE.DirectionalLight(0xffffff, 1.5);
      this.light = light;
      light.position.set(0, 0, 0);
      this.scene.add(light);
    },
    animate() {
      this.orbitControls.update();
      this.light.position.set(
        this.camera.position.x,
        this.camera.position.y,
        this.camera.position.z
      );
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.animate);
    },
    //获得着色器材质
    getShaderMaterial() {
      const vertexShader = `
out vec3 pos;
out vec3 n;
void main() {
  pos = (modelMatrix * vec4(position, 1.0)).xyz;
  n = normalize((transpose(inverse(modelMatrix)) * vec4(normal, 0.0)).xyz);
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0); // 计算顶点位置
}
`;
      const fragmentShader = `
uniform vec3 size; // 定义时间变量
uniform vec3 color;
in vec3 pos;
in vec3 n;
void main() {
  
  vec3 vcolor;
  if (
    abs(pos.x) <= size.x &&
    abs(pos.y) <= size.y &&
    abs(pos.z) <= size.z * 2.0 &&  
    pos.z >= -0.005
    ) { // 上半部分设置为红色
    vcolor = color;
  } else { // 下半部分设置为绿色
    vcolor = vec3(0.5, 0.0, 1.0);
  }
  float light = dot(n , normalize(cameraPosition - pos));

  gl_FragColor = vec4(vcolor * light, 1.0) ; // 定义片元颜色
}
`;
      const { x, y, z } = this.printerSize;
      console.log("着色器材质", this.printerSize);
      console.log("z", z);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          size: { value: { x: x / 2, y: y / 2, z: z / 2 } },
          color: { value: new THREE.Vector3(...this.color.unChecked) },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
      });
      return material;
    },
    //加载stl模型
    loadStlModel(path, modelInfo, resolve, reject) {
      let msg = (text) => {
        return;
        let info = document.createElement("p");
        let canv = document.querySelector("div");
        info.innerText = `${text}`;
        canv.append(info);

        let br = document.createElement("br");
        canv.append(br);
      };
      msg(`${path}`);
      // this.$message.success(`${modelInfo}`);
      // this.$message.success(`${resolve}`);
      const loader = new STLLoader1();
      this.loader = loader;
      this.loadTime = Date.now();

      //loader加载成功调用
      loader
        .loadAsync(path, (xhr) => {
          let percentage = Number(
            ((xhr.loaded * 100) / this.totalSize).toFixed(0)
          );
          msg(`${xhr.loaded}/${xhr.total}`);

          let curTime = Date.now();
          if (curTime - this.loadTime >= 1000) {
            this.percentage = percentage;
            this.loadTime = curTime;
          }
        })
        .then(async (geometry) => {
          msg(`start parse`);

          geometry.computeVertexNormals();
          // 定义顶点着色器和片元着色器
          const material = this.getShaderMaterial();
          const camera = this.camera;
          const scene = this.scene;
          const mesh = new THREE.Mesh(geometry, material);
          mesh.isSelected = false;
          console.log("scene", this.scene);
          this.scene.add(mesh);
          msg(`add mesh`);

          //获取stl模型尺寸
          let size = await this.adaptPrint(mesh);
          console.log("next", size);
          this.modelSize = size;
          mesh.adaptSize = size;
          //加载后使模型居中
          geometry.center();
          // 设置模型的位置
          mesh.position.set(0, 0, size.z / 2);
          //生成一个包围盒
          const lineSegments = this.getBoundingBox(size, mesh);
          this.scene.add(lineSegments);
          lineSegments.visible = false;
          mesh.isShow = true;
          mesh.isSelected = false;
          mesh.modelInfo = modelInfo;
          console.log("modelInfo", mesh.modelInfo);
          this.objects.push(mesh);
          this.modelList.push(mesh);
          this.modelMap.set(mesh, lineSegments);
          mesh.initPosition = { ...mesh.position };
          msg(`add position`);
          // 创建鼠标拾取器
          const raycaster = new THREE.Raycaster();
          const mouse = new THREE.Vector2();
          let that = this;
          // 添加鼠标点击事件
          if (this.$refs.slicerContainer) {
            this.$refs.slicerContainer.addEventListener(
              "mousedown",
              onMouseDown,
              false
            );
          }
          function onMouseDown(event) {
            if (event.target.tagName !== "CANVAS") return;
            if (that.transformControls.dragging) return;
            // 获取鼠标点击位置
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            /* mouse.y =
              -(
                (event.clientY + window.innerHeight / 10) /
                window.innerHeight
              ) *
                2 +
              1; */
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            console.log("mouse", mouse);
            // 设置射线
            raycaster.setFromCamera(mouse, camera);
            // 获取射线与模型相交的数组
            const intersects = raycaster.intersectObjects(that.objects, false);
            // 如果有相交的模型
            console.log("cancel");
            if (intersects.length > 0) {
              // 获取第一个相交的模型
              const intersected = intersects[0];
              if (intersected.object instanceof THREE.Mesh) {
                intersected.object.material.uniforms.color.value.set(
                  ...that.color.checked
                );
                intersected.object.isSelected = true;
                //将移动中的初始位置为选中模型时的位置
                that.position = intersected.object.position;
                that.copyPosition = { ...that.position };
                // 获取模型的最大最小坐标
                const box = that.getModlBoxFromObject(intersected.object, true);
                that.copyPosition.z = box.min.z;
                that.scale.per = {
                  x: intersected.object.scale.x * 100,
                  y: intersected.object.scale.y * 100,
                  z: intersected.object.scale.z * 100,
                };
                //选中模型时将模型旋转的角度赋值给变量
                that.spin = that.getSpinEluerAngel();
                console.log("spin", that.spin);

                //产生平移轨道控件
                that.transformControls.attach(intersected.object);
                that.scale.size = that.getModelSize(intersected.object, true);
                // 设置模型的颜色
                intersected.object.isSelected = true;
                if (that.objects.length === 1) {
                  that.checked = true;
                }
                for (let obj of that.objects) {
                  //如果模型不是选中的模型，将模型状态变为非选中，同时去除外边框改变回原来的颜色
                  if (obj !== intersected.object) {
                    obj.isSelected = false;
                    obj.material.uniforms.color.value.set(
                      ...that.color.unChecked
                    );
                    // scene.remove(that.modelMap.get(obj));
                    that.modelMap.get(obj).visible = false;
                  }
                }
                that.$forceUpdate();
                // intersected.object.material.uniforms.color.value.set(1,0,1);
                if (intersected.object.isShow) {
                  const lineSegments1 = that.modelMap.get(intersected.object);
                  lineSegments1.visible = true;
                  // scene.add(lineSegments1);
                } else {
                  const lineSegments1 = that.modelMap.get(intersected.object);
                  lineSegments1.visible = false;
                  // scene.add(lineSegments1);
                }
              } else {
                that.cancelSelectModels();
              }
            } else {
              that.cancelSelectModels();
            }
          }
          resolve();
        })
        .catch((err) => {
          reject();
        });

      msg(`finish load`);
    },
    //包围盒各顶点设置
    box(width, height, depth) {
      (width = width * 0.5), (height = height * 0.5), (depth = depth * 0.5);
      const geometry = new THREE.BufferGeometry();
      const position = [];
      position.push(
        -width,
        -height,
        -depth, // -15,-25,-5
        -width,
        -height + height / 2,
        -depth,
        -width,
        height - height / 2,
        -depth,
        -width,
        height,
        -depth,
        -width,
        height,
        -depth,
        -width + 0.7 * width,
        height,
        -depth,
        width - 0.7 * width,
        height,
        -depth,
        width,
        height,
        -depth,
        width,
        height,
        -depth,
        width,
        height - height / 2,
        -depth,
        width,
        -height + height / 2,
        -depth,
        width,
        -height,
        -depth,

        width,
        -height,
        -depth,
        width - 0.7 * width,
        -height,
        -depth,
        -width + 0.7 * width,
        -height,
        -depth,
        -width,
        -height,
        -depth,

        -width,
        -height,
        depth,
        -width,
        -height + height / 2,
        depth,
        -width,
        height - height / 2,
        depth,
        -width,
        height,
        depth,

        -width,
        height,
        depth,
        -width + 0.7 * width,
        height,
        depth,
        width - 0.7 * width,
        height,
        depth,
        width,
        height,
        depth,

        width,
        height,
        depth,
        width,
        height - height / 2,
        depth,
        width,
        -height + height / 2,
        depth,
        width,
        -height,
        depth,

        width,
        -height,
        depth,
        width - 0.7 * width,
        -height,
        depth,
        -width + 0.7 * width,
        -height,
        depth,
        -width,
        -height,
        depth,
        -width,
        -height,
        depth,
        -width,
        -height,
        depth - 0.5 * depth,
        -width,
        -height,
        -depth + 0.5 * depth,
        -width,
        -height,
        -depth,

        -width,
        height,
        depth,
        -width,
        height,
        depth - 0.5 * depth,
        -width,
        height,
        -depth + 0.5 * depth,
        -width,
        height,
        -depth,

        width,
        height,
        depth,
        width,
        height,
        depth - 0.5 * depth,
        width,
        height,
        -depth + 0.5 * depth,
        width,
        height,
        -depth,

        width,
        -height,
        depth,
        width,
        -height,
        depth - 0.5 * depth,
        width,
        -height,
        -depth,
        width,
        -height,
        -depth + 0.5 * depth
      );
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(position, 3)
      );
      return geometry;
    },
    //让不同尺寸的模型自适应打印机尺寸
    async adaptPrint(model) {
      // 获取模型的最大最小坐标
      const box = this.getModlBoxFromObject(model, true);
      console.log("initModelSizeBox", box);
      const min = box.min;
      const max = box.max;
      //计算模型尺寸
      const size = new THREE.Vector3();
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      model.initSize = { ...size };

      let xScale = this.printerSize.x / size.x;
      let yScale = this.printerSize.y / size.y;
      let zScale = this.printerSize.z / size.z;
      //判断模型是否超出打印机大小
      if (xScale <= 1 || yScale <= 1 || zScale <= 1) {
        let minScale = Math.min(xScale, yScale, zScale) * 0.6;
        model.scale.set(minScale, minScale, minScale);
        size.x *= minScale;
        size.y *= minScale;
        size.z *= minScale;
      }
      return size;
    },
    //根据模型尺寸获得包围盒
    getBoundingBox(size, mesh) {
      console.log("box", size);
      const geometryBox = this.box(size.x, size.y, size.z);
      const lineSegments = new THREE.LineSegments(
        geometryBox,
        new THREE.LineBasicMaterial({ color: "#e7c200" })
      );
      lineSegments.name = "box";
      lineSegments.computeLineDistances();

      // lineSegments.rotation.x = -Math.PI / 2;
      /* lineSegments.position.set(
        mesh.position.x,
        mesh.position.y,
        mesh.position.z
      ); */
      const box = this.getModlBoxFromObject(mesh, true);
      lineSegments.position.set(
        box.max.x * 0.5 + box.min.x * 0.5,
        box.max.y * 0.5 + box.min.y * 0.5,
        (box.max.z - box.min.z) * 0.5
      );
      return lineSegments;
    },
    packLayout() {
      this.isPackLayout = true;
      setTimeout(() => {
        var Packer = function (w, h) {
          console.log(this, "this");
          this.init(w, h);
        };
        Packer.prototype = {
          init: function (w, h) {
            this.root = { x: 0, y: 0, w: w, h: h };
          },

          fit: function (blocks) {
            var n, node, block;
            for (n = 0; n < blocks.length; n++) {
              block = blocks[n];
              if ((node = this.findNode(this.root, block.w, block.h)))
                block.fit = this.splitNode(node, block.w, block.h);
            }
          },

          findNode: function (root, w, h) {
            if (root.used)
              return (
                this.findNode(root.right, w, h) ||
                this.findNode(root.down, w, h)
              );
            else if (w <= root.w && h <= root.h) return root;
            else return null;
          },

          splitNode: function (node, w, h) {
            node.used = true;
            node.down = { x: node.x, y: node.y + h, w: node.w, h: node.h - h };
            node.right = { x: node.x + w, y: node.y, w: node.w - w, h: h };
            return node;
          },
        };
        var packer = new Packer(this.printerSize.x, this.printerSize.y); // 初始化一个容器
        var blocks = [
          { w: 600, h: 300 },
          { w: 400, h: 400 },
          { w: 100, h: 200 },
        ];
        blocks = [];
        let copyObjects = [...this.objects];
        console.log("copyObjects", copyObjects);
        copyObjects.sort((a, b) => {
          console.log("a", a);
          console.log("b", b);
          let aSize = this.getModelSize(a, true);
          let bSize = this.getModelSize(b, true);
          return bSize.y - aSize.y;
        });
        for (let model of copyObjects) {
          const size = this.getModelSize(model, true);
          blocks.push({
            w: size.x,
            h: size.y,
          });
        }
        // packer.fit(blocks)
        packer.fit(blocks); // 装箱
        // console.log(blocks[0].fit.x, blocks[0].fit.y); // 输出第一个块的位置
        //收集没有放入的模型
        let models = [];
        for (let i = 0; i < copyObjects.length; i++) {
          // const z = this.getModelSize(copyObjects[i], true).z / 2;
          const box = this.getModlBoxFromObject(copyObjects[i], true);
          let box1 = box;
          let z = copyObjects[i].position.z - box.min.z;
          if (blocks[i].fit) {
            const x =
              blocks[i].fit.x -
              this.printerSize.x / 2 +
              (copyObjects[i].position.x - box1.min.x);
            const y =
              blocks[i].fit.y -
              this.printerSize.y / 2 +
              (copyObjects[i].position.y - box1.min.y);
            copyObjects[i].position.set(x, y, z);
            let selectedModel = copyObjects[i];
            const box = this.getModlBoxFromObject(selectedModel, true);
            // selectedModel.position.z -= box.min.z;
            const lineSegments = this.modelMap.get(selectedModel);
            this.scene.remove(lineSegments);
            const size = this.getModelSize(selectedModel, true);
            copyObjects[i].initPosition = { ...copyObjects[i].position };
            copyObjects[i].initSize = { ...size };
            const newLineSegments = this.getBoundingBox(size, selectedModel);
            this.modelMap.set(selectedModel, newLineSegments);
            // if (copyObjects[i].isSelected) {
            // /*   newLineSegments.visible = true;
            // } else { */
            //   newLineSegments.visible = false;
            // }
            this.scene.add(newLineSegments);
            newLineSegments.visible = false;
            newLineSegments.position.set(
              box.max.x * 0.5 + box.min.x * 0.5,
              box.max.y * 0.5 + box.min.y * 0.5,
              (box.max.z - box.min.z) * 0.5
            );
            if (this.objects.length === 1) {
              selectedModel.position.set(
                selectedModel.position.x - box.getCenter(new THREE.Vector3()).x,
                selectedModel.position.y - box.getCenter(new THREE.Vector3()).y,
                selectedModel.position.z - box.min.z
              );
              newLineSegments.position.set(
                0,
                0,
                selectedModel.position.z - box.min.z
              );
              if (selectedModel.isSelected) {
                newLineSegments.visible = true;
              }
              this.copyPosition = { x: 0, y: 0, z: 0 };
            }
          } else {
            //没放入的模型
            /* this.objects[i].position.set(
            this.printerSize.x / 2 + 200,
            this.printerSize.y / 2 + 200,
            z
          ); */
            models.push(copyObjects[i]);
          }
        }
        this.cancelSelectModels();
        /* var printSize = this.printerSize;
      let XPostive = -printSize.x / 2;
      let YPostive = -printSize.y / 2;
      let circle = 1;
      //记录在X轴 Y轴的绝对值长度
      let x = 0;
      let y = 0;
      let axes = "Y+";
      let lineSegments;
      let maxYPositive = -printSize.x / 2;
      let yPostiveToxPositive = 0;
      let xPositiveToyMinus = 0;
      let yMinusToxMinus = 0;
      let xMinusToyPositive = models[0]
        ? [0, this.getModelSize(models[0], true)]
        : [];
      // 记录Y轴正方向宽度坐标
      for (let i = 0; i < models.length; i++) {
        let xMinusToyPositiveSum = xMinusToyPositive.reduce((pre, cur) => {
          return pre + cur;
        }, 0);
        let size = this.getModelSize(models[i], true);
        if (axes === "Y+") {
          //切换X+轴方向
          if (y > printSize.y) {
            yPostiveToxPositive += size.y;
            axes = "X+";
            y = 0;
            XPostive = -printSize.x / 2 + size.x / 2;
            YPostive = printSize.y / 2 + yPostiveToxPositive - size.y / 2;
            x += size.x;
            models[i].position.set(XPostive, YPostive, size.z / 2);
          } else {
            x = 0;
            if (circle == 1) {
              XPostive = -printSize.x / 2 - size.x / 2;
            } else {
              XPostive = -printSize.x / 2 - size.x / 2 - xMinusToyPositiveSum;
            }
            if (!models[i - 1]) {
              YPostive = size.y / 2 + YPostive;
            } else {
              YPostive =
                size.y / 2 +
                YPostive +
                this.getModelSize(models[i - 1],true).y / 2 +
                yPostiveToxPositive;
            }
            y += size.y;
            models[i].position.set(XPostive, YPostive, size.z / 2);
            lineSegments = this.modelMap.get(models[i]);
            lineSegments.position.set(XPostive, YPostive, size.z / 2);
          }
        }
        // X轴正方向
        else if (axes === "X+") {
          console.log("x+");
          if (x <= printSize.x) {
            y = 0;
            XPostive =
              XPostive + size.x / 2 + this.getModelSize(models[i - 1],true).x / 2;
            YPostive = printSize.y / 2 + size.y / 2;
            x += size.x;
            models[i].position.set(XPostive, YPostive, size.z / 2);
          }
          //切换到Y-轴方向
          else {
            console.log("x+ --- y-");
            axes = "Y-";
            y += size.x;
            XPostive = printSize.x / 2 + size.x / 2;
            YPostive = printSize.y / 2 - size.y / 2;
            models[i].position.set(XPostive, YPostive, size.z / 2);
          }
        }

        //Y轴负方向
        else if (axes === "Y-") {
          console.log("y-");
          if (y <= printSize.y) {
            x = 0;
            XPostive = printSize.x / 2 + size.x / 2;
            YPostive =
              YPostive - size.y / 2 - this.getModelSize(models[i - 1],true).y / 2;
            y += size.y;
          }
          //切换到X-轴方向
          else {
            console.log("y- ---- x-");
            axes = "X-";
            XPostive = printSize.x / 2 - size.x / 2;
            YPostive = -printSize.y / 2 - size.y / 2;
            x += size.x;
          }
          models[i].position.set(XPostive, YPostive, size.z / 2);
        }

        //X轴负方向
        else if (axes === "X-") {
          console.log("x-");
          if (x <= printSize.x) {
            XPostive =
              XPostive - size.x / 2 - this.getModelSize(models[i - 1],true).x / 2;
            YPostive = -printSize.y / 2 - size.y / 2;
            x += size.x;
          }
          //切换到Y+轴方向
          else {
            circle++;
            console.log("x- ---- y+");
            axes = "Y+";
            y = 0;
            y += size.y / 2;
            XPostive = -printSize.x / 2 - xMinusToyPositiveSum - size.x / 2;
            YPostive = -printSize.y / 2 + size.y / 2;
            xMinusToyPositive.push(size.x);
          }
          models[i].position.set(XPostive, YPostive, size.z / 2);
        }
      } */
        //x和y方向的绝对值
        let ySum = 0,
          xSum = 0;
        let yPostive = 0,
          xPostive = 0,
          yMinus = 0,
          xMinus = 0;
        let yPostiveMax = 0.01,
          xPostiveMax = 0.01,
          yMinusMax = 0.01,
          xMinusMax = 0.01;
        let yPostiveModels = [],
          xPostiveModels = [],
          yMinusModels = [],
          xMinusModels = [];
        for (let i = 0; i < models.length; i++) {
          const size = this.getModelSize(models[i], true);
          let box = this.getModlBoxFromObject(models[i], true);
          let center = {
            x: (box.min.x + box.max.x) / 2,
            y: (box.min.y + box.max.y) / 2,
            z: (box.min.z + box.max.z) / 2,
          };
          let initX = models[i].position.x;
          let initY = models[i].position.y;
          let initZ = models[i].position.z;
          let { x, y, z } = size;
          //Y+
          if (yPostive == xPostive && xPostive == yMinus && yMinus == xMinus) {
            xSum = 0;
            ySum += size.y;
            if (!yPostiveModels[yPostive]) {
              yPostiveModels[yPostive] = [];
            }
            let xPosition, yPosition, xdiff, ydiff;
            yPostiveModels[yPostive].push(size.x);
            xPosition = -this.printerSize.x / 2 - x / 2 - yPostiveMax;
            yPosition = -this.printerSize.y / 2 + ySum - y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (ySum >= this.printerSize.y) {
              yPostiveMax += Math.max(...yPostiveModels[yPostive]);
              yPostive++;
            }
          }
          //模型排X+
          else if (yPostive - xPostive == 1) {
            ySum = 0;
            xSum += size.x;
            if (!xPostiveModels[xPostive]) {
              xPostiveModels[xPostive] = [];
            }
            let xPosition, yPosition;
            xPostiveModels[xPostive].push(size.y);
            xPosition = -this.printerSize.x / 2 + xSum - x / 2;
            yPosition = this.printerSize.y / 2 + xPostiveMax + y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            console.log("xPostion", xPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (xSum >= this.printerSize.x) {
              xPostiveMax += Math.max(...xPostiveModels[xPostive]);
              xPostive++;
            }
          }
          //模型排Y-
          else if (xPostive - yMinus == 1) {
            xSum = 0;
            ySum += size.y;
            if (!yMinusModels[yMinus]) {
              yMinusModels[yMinus] = [];
            }
            let xPosition, yPosition;
            yPostiveModels[yMinus].push(size.x);
            xPosition = this.printerSize.x / 2 + x / 2 + yMinusMax;
            yPosition = this.printerSize.y / 2 - ySum + y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            console.log("xPostion", xPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (ySum >= this.printerSize.y) {
              yMinusMax += Math.max(...yMinusModels[yMinus]);
              yMinus++;
            }
          }
          //模型排X-
          else if (yMinus - xMinus == 1) {
            ySum = 0;
            xSum += size.x;
            if (!xMinusModels[xMinus]) {
              xMinusModels[xMinus] = [];
            }
            let xPosition, yPosition;
            xPostiveModels[xMinus].push(size.y);
            xPosition = this.printerSize.x / 2 - xSum + x / 2;
            yPosition = -this.printerSize.y / 2 - xMinusMax - y / 2;
            xPosition = initX - (center.x - xPosition);
            yPosition = initY - (center.y - yPosition);
            console.log("xPostion", xPosition);
            models[i].position.set(
              xPosition,
              yPosition,
              models[i].position.z - box.min.z
            );
            if (xSum >= this.printerSize.x) {
              xMinusMax += Math.max(...xMinusModels[xMinus]);
              xMinus++;
            }
          }
          models[i].initPosition = {
            ...models[i].position,
          };
          models[i].initSize = { ...size };
          const newLineSegments = this.getBoundingBox(
            this.getModelSize(models[i], true),
            models[i]
          );
          this.scene.remove(this.modelMap.get(models[i]));
          this.modelMap.set(models[i], newLineSegments);
          this.scene.add(newLineSegments);
          newLineSegments.visible = false;
        }
        console.log("自动布局完成");
        if (this.message) {
          this.message.close();
        }
        this.message = this.$message({
          type: "success",
          duration: 1000,
          message: this.$t("slicer.autoLayoutComp"),
          offset: 50,
        });
        this.isPackLayout = false;
      }, 1000);
    },
    //获得模型尺寸
    getModelSize(mesh, isPrecise = false) {
      // 获取模型的最大最小坐标
      //判断模型是否旋转，旋转了就精确计算大小
      let { x, y, z } = mesh.rotation;
      if (x === 0 && y === 0 && z === 0) {
        isPrecise = false;
      }
      const box = this.getModlBoxFromObject(mesh, isPrecise);
      const min = box.min;
      const max = box.max;
      // 计算模型的缩放比例
      const scale = Math.max(max.x - min.x, max.y - min.y, max.z - min.z);
      const size = new THREE.Vector3();
      size.x = max.x - min.x;
      size.y = max.y - min.y;
      size.z = max.z - min.z;
      return size;
    },
    //取消选中模型
    cancelSelectModels() {
      this.checked = false;
      //将移动的坐标归0
      this.copyPosition.x = 0;
      this.copyPosition.y = 0;
      this.copyPosition.z = 0;
      //将旋转实际模型尺寸归0
      this.scale.size = {
        x: 0,
        y: 0,
        z: 0,
      };
      this.scale.per = {
        x: 100,
        y: 100,
        z: 100,
      };
      this.spin = {
        x: 0,
        y: 0,
        z: 0,
      };
      // this.scaleReset()
      for (let obj of this.objects) {
        if (obj.isSelected) {
          const lineSegments = this.modelMap.get(obj);
          lineSegments.visible = false;
          obj.material.uniforms.color.value.set(...this.color.unChecked);
          obj.isSelected = false;
          this.transformControls.attach();
          this.transformControls.visible = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.slicer-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #adafb6;
  overflow: hidden;
  /deep/ .el-loading-mask {
    .el-loading-spinner {
      .el-icon-loading {
        font-size: 40px;
        margin-bottom: 10px;
      }
    }
  }
  .mask-packLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(245, 245, 245, 0.6);
    z-index: 9999;
    span.txt {
      color: #fff;
    }
  }
  .mask {
    position: absolute;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background: rgba(26, 26, 26, 0.6);
    .el-progress {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      /deep/ .el-progress__text {
        color: #fff !important;
      }
    }
  }
  .top-nav {
    position: fixed;
    z-index: 9;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    padding: 0 10px 0 15px;
    i.el-icon-arrow-left {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
    .modelList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      height: 25px;
      background: #d71518;
      border-radius: 3px;
      color: #fff;
      span {
        font-size: 14px;
      }
      .icon-a-moxingliebiao1 {
        font-size: 16px;
      }
    }
  }
  //切片参数
  .slicer-parmars {
    position: absolute;
    top: 54px;
    left: 0;
    z-index: 9;
    display: flex;
    transition: all 0.2s;
    .params {
      width: 340px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          padding-left: 10px;
          align-items: center;
          width: 170px;
          height: 40px;
          background: rgba(0, 0, 0, 0.4);
          border: 1px solid #ccc;
          border-top: none;
          border-left: none;
          color: #fff;
          font-size: 14px;
          .iconfont {
            margin-right: 10px;
            font-size: 16px;
          }
          span {
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
          }
        }
        li:nth-child(2n) {
          border-right: none;
        }
      }
    }
    .fold {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 80px;
      background: rgba(25, 25, 26, 0.8);
      border-radius: 0px 4px 4px 0px;
      text-align: center;
      .el-icon-arrow-left,
      .el-icon-arrow-right {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  //控制器
  .controls {
    position: absolute;
    top: 190px;
    left: 5px;
    z-index: 99;
    ul {
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        margin-bottom: 4px;
        color: #fff;
        .iconfont {
          font-size: 19px;
          margin-bottom: 5px;
        }
        span {
          font-size: 9px;
          text-align: center;
        }
      }
    }
  }

  .controls-operate {
    position: absolute;
    z-index: 99;
    bottom: 0;
    color: #fff;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      color: #fff;
      span.name {
        font-size: 16px;
        font-weight: 500;
      }
      .el-icon-close {
        font-size: 18px;
      }
    }
    .el-input-number {
      width: 110px;
      height: 40px;
      /deep/ .el-input-number__decrease,
      /deep/ .el-input-number__increase {
        border: none;
        background: inherit;
        color: #fff;
      }
      /deep/ .el-input {
        input {
          padding: 0 30px;
          background: inherit;
          color: #fff;
        }
      }
    }
    .move {
      width: 375px;
      height: 235px;
      padding: 0 15px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 12px 12px 0px 0px;
      .operate {
        display: flex;
        justify-content: space-between;
      }
      .axes {
        display: flex;
        justify-content: space-around;
        font-size: 15px;
        margin-top: 16px;
        font-weight: 500;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        margin-top: 27px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        .sink {
          width: 150px;
          height: 40px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #ffffff;
          border-radius: 4px;
        }
        .center {
          width: 88px;
          height: 40px;
          background: rgba(255, 255, 255, 0);
          border: 1px solid #ffffff;
          border-radius: 4px;
        }
        .reset {
          width: 88px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          color: #111;
        }
      }
    }
    .scale {
      width: 375px;
      height: 322px;
      padding: 0 15px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 12px 12px 0px 0px;
      .scale-control-btns {
        display: flex;
        align-items: center;
        width: 345px;
        height: 30px;
        margin-bottom: 14px;
        padding: 0 2px;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #ffffff;
        border-radius: 4px;
        div {
          width: 83px;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border-radius: 2px;
          flex: 1;
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
        }
      }
      .locking-ratio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        margin-top: 15px;
        span.name {
          margin-right: 10px;
          font-size: 14px;
          font-weight: 800;
          color: #ffffff;
        }
        /deep/ .el-switch {
          span.el-switch__core {
            border-color: #fff !important;
          }
          span.el-switch__core::after {
            background-color: #626267;
          }
        }
        /deep/ .is-checked {
          span.el-switch__core {
            border-color: #fff !important;
          }
          span.el-switch__core::after {
            background-color: #fff;
          }
        }
      }
      .operate {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }
      .axes {
        display: flex;
        justify-content: space-around;
        font-size: 15px;
        margin-top: 16px;
        font-weight: 500;
      }
      .btns {
        margin-top: 27px;
        .reset {
          width: 88px;
          height: 40px;
          margin: 0 auto;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 4px;
          color: #111;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
        }
      }
    }
    .rotate {
      width: 375px;
      height: 340px;
      padding: 0 15px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 12px 12px 0px 0px;
      .top {
        padding-bottom: 10px;
      }
      .axes {
        .label {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        .value {
          position: relative;
          display: flex;
          margin-bottom: 10px;
          .angle {
            position: absolute;
            left: 103px;
            top: 10px;
          }
          .el-input-number {
            width: 175px;
            height: 40px;
            /deep/ .el-input {
              height: 100%;
            }
          }
          .add45 {
            margin: 0 10px;
          }
          .add45,
          .sub45 {
            width: 75px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: rgba(238, 238, 238, 0);
            border: 1px solid #ffffff;
            border-radius: 2px;
          }
        }
      }
      .reset {
        width: 345px;
        height: 40px;
        margin-top: 20px;
        font-size: 14px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 4px;
        color: #111;
        text-align: center;
        line-height: 40px;
      }
    }

    .copy {
      width: 375px;
      height: 227px;
      padding: 0 15px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 12px 12px 0px 0px;
      .top {
        padding-bottom: 10px;
      }
      .count {
        .label {
          margin-bottom: 15px;
          font-size: 15px;
          font-weight: 500;
        }
        .value {
          .el-input-number {
            width: 100%;
          }
        }
      }
      .apply {
        width: 345px;
        height: 40px;
        margin-top: 25px;
        font-size: 14px;
        font-weight: 500;
        color: #111;
        text-align: center;
        line-height: 40px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 4px;
      }
    }
  }

  //模型列表
  .modelList-control {
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: 100%;
    height: 260px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 12px 12px 0px 0px;
    .top {
      display: flex;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .name {
          font-size: 16px;
          font-weight: 500;
          color: #fff;
        }
        .all {
          margin-left: 20px;
          font-size: 14px;
          color: #999;
        }
      }
      i.el-icon-close {
        color: #fff;
        font-size: 18px;
      }
    }
    ul {
      height: 160px;
      overflow: auto;
      li {
        position: relative;
        display: flex;
        height: 80px;
        padding: 10px 14px;
        cursor: pointer;
        .icon {
          display: flex;
          align-items: center;
          margin-right: 9px;
          .iconfont {
            color: #ccc;
          }
        }
        .pic {
          width: 60px;
          height: 60px;
          border-radius: 8px;
          margin-right: 11px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
          }
        }
        span.name {
          font-size: 14px;
          font-weight: 500;
          color: #ccc;
        }
        i.el-icon-delete {
          position: absolute;
          right: 21px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          color: #999;
        }
      }
    }
    .addModel {
      width: 345px;
      height: 40px;
      margin: 10px auto;
      background: #d71518;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: 20px;
    }
  }

  .slicer-btn {
    position: absolute;
    z-index: 9;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 345px;
    height: 40px;
    background: #d71518;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    i.icon-a-qiepianpeizhi4 {
      color: #fff;
    }
    span {
      margin-left: 5px;
      font-weight: 500;
      // font-size: 17px;
    }
  }

  .dialogs {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(26, 26, 26, 0.6);
    .modelBeyondBorderDialog,
    .modelSizeDialog,
    .excludeModelDialog,
    .exitSlicerDialog,
    .modelHoverDialog {
      position: absolute;
      z-index: 999;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 280px;
      // height: 150px;
      padding: 30px 28px 10px;
      border-radius: 15px;
      background: #fff;
      color: #333;
      font-size: 12px;
      line-height: 20px;
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
      .knowed {
        width: 80px;
        height: 30px;
        margin: 30px auto;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        background: #d71518;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        div {
          min-width: 80px;
          height: 30px;
          border-radius: 5px;
          background: #d71518;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
        }
        .cancel {
          border-radius: 5px;
          border: 1px solid #a0a0a0;
          background: #fff;
          color: #a0a0a0;
        }
      }
    }
    .delFinalSingleModelDialog {
      position: absolute;
      z-index: 999;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 280px;
      height: 150px;
      padding: 30px 28px 0;
      border-radius: 15px;
      background: #fff;
      color: #333;
      font-size: 12px;
      line-height: 20px;
      box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.05);
      .knowed {
        width: 80px;
        height: 30px;
        margin: 30px auto;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 5px;
        background: #d71518;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        div {
          width: 80px;
          height: 30px;
          border-radius: 5px;
          background: #d71518;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
        }
        .cancel {
          border-radius: 5px;
          border: 1px solid #a0a0a0;
          background: #fff;
          color: #a0a0a0;
        }
      }
    }
    .exitSlicerDialog,
    .modelHoverDialog {
      .txt {
        text-align: center;
        color: #333;
        font-size: 16px;
        font-weight: 500;
      }
      .btns {
        div {
          width: 110px;
          height: 40px;
          border-radius: 46px !important;
          line-height: 40px;
          text-align: center;
        }
        .cancel {
          margin-right: 20px;
          border: 1px solid #666;
        }
      }
    }
    .modelHoverDialog {
      .txt {
        text-align: left;
      }
    }
  }
  /*  .canvasDom {
    transform: translateY(-10vh);
  } */
}
</style>