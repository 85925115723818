import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router  from './router';
import 'element-ui/lib/theme-chalk/index.css';


//引入样式
import './style/base.css'
import './style/normal.css'

//引入api.js
import './api/index.js'

Vue.use(ElementUI);
Vue.config.productionTip = false

import 'lib-flexible/flexible.js'
// import 'amfe-flexible'  // 动态设置了rem的基准值
import i18n  from './lang/index';

new Vue({
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
