export default {
  slices: 'Slices',
  support: 'Support',
  fine: 'Fine',
  medium: 'Medium',
  coarse: 'Coarse',
  slicer: {
    move: 'Move',
    scale: 'Scale',
    rotate: 'Rotate',
    autoLayout: 'Auto Layout',
    copy: 'Copy',
    slicer: 'Slice',
    modelList: 'Model List',
    loadedModel: 'Loaded',
    total: 'Total',
    addModel:'Add Model',
    modelSink: ' Model Sink',
    center: 'Center',
    reset: 'Reset',
    actual: 'Actual',
    percentage: 'Percentage',
    lockRatio: 'Lock Ratio',
    scaleToFit: 'Scale to Fit',
    quantity: 'Quantity',
    apply: 'Apply',
    modelLoading: 'Model Loading',
    autoLayoutInPro: 'Auto Layout in Progress',
    autoLayoutComp: 'Auto Layout Complete'
  },
  slicerPars: {
    title: 'Resin-Slicing Configuration',
    allPars: 'All Parameters',
    configFile: 'Configuration File',
    save: 'Save',
    printSet: {
      title: 'Print Settings',
      resinType: {
        label: 'Resin Type',
        value: ''
      }, 
      layThick: {
        label: 'Layer Thickness',
        value: ''
      },
      singLayExpTime: 'Single Layer Exposure Time',
      bottomLayExpTime: 'Bottom Layer Exposure Time',
      expDelay: 'Exposure Delay',
      bottomLayExpLay: 'Bottom Layer Exposure Layers',
      bottomLayExpTranLay: 'Bottom Lay-Exposure Transition Layers',
      liftHeight1: 'Lift Height 1',
      lifgSpeed1: ' Lift Speed 1',
      liftHeight2: 'Lift Height 2',
      lifgSpeed2: ' Lift Speed 2',
      lowerSpeed: 'Lowering Speed',
      zAxLiftAfterPrint: 'Z-Axis Lift After Print'
    },
    highSet: {
      modelLiftHeight: 'Modoel Lifting Height',
      supportType: 'Support Type',
      title: 'Advanced Settings',
      innerDiaComp: 'Inner Diameter Compensation',
      outDiaComp: 'Outer Diameter Compensation',
      xAxisScaleComp: 'X-Axis Scale Compensation',
      yAxisScaleComp: 'Y-Axis Scale Compensation',
      zAxisScaleComp: 'Z-Axis Scale Compensation',
      antial: {
        label: 'Antialiasing',
        value: 'No Antialiasing at Level "1"'
      },
      autoSup: 'Automatic Supports',
      antDisAtLevel1: 'No Antialiasing at Level "1"',
      grayLevel: 'Grayscale Level',
      imgBlurPx: 'Image Blur Pixels',
      supDensity: 'Support Density',
      addBase: 'Add Base',
      open: 'Open',
      close: 'Close'
    },
    saveFailTip: 'Save failed. Your slicing parameters cannot be empty, or there is a conflict in your configuration file name.',
    saveNameConflict: 'Configuration file name cannot be empty',
    newConfigSaveSuc: 'New configuration saved successfully',
    updateSuc: 'Update successful',
    updateFail: 'Update failed'
  },
  delDialog : {
    tip: 'Tip',
    txt: 'Do you want to delete this configuration parameter? The deletion will not be recoverable',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  outOfBoundDialog : {
    tip: 'Tip',
    txt: 'The model is out of printer boundaries. Adjust the position or size of the model.(Adding supports to the model may potentially exceed the printing boundaries)',
    know: 'Have known'
  },
  adaptModelDialog : {
    tip: 'Tip',
    txt: 'Some models are too large or too small. Should I adjust them to the appropriate size?',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  addModelDialog : {
     modelList: {
      label: 'Model list',
      value: 'Add a model by dragging it to the model list'
     },
     modelBase: 'Model base',
     searchModel: 'Search model',
     noResults: 'No search results are available'
  },
  excludeModelDialog: {
    tip: 'Tip',
    txt: 'Some models exceed the printer boundaries. Do you want to exclude these models during slicing?',
    confirm: 'Yes, proceed with slicing',
    cancel: 'Cancel'
  },
  delFinalSingleModelDialog: {
    tip: 'Tip',
    txt: 'Deleting will exit the slice preview. Do you want to continue?',
    yes: 'Yes',
    no: 'No'
  },
  modelHoverDialog : {
    tip: 'Tip',
    txt: 'The model is suspended, which may cause printing errors. Do you want to continue?',
    yes: 'Yes',
    no: 'No'
  },
  machineSelect: {
    title: 'Select Model',
    addMachine: 'Add Model',
    commonModels: ' Common Models',
    size: 'Size',
    resolution: 'Resolution',
    define: {
      label: 'Custom',
      value: 'If using a non-Octopus Core official model, you can choose to add a custom model.'
    }
  },
  addMachine: {
    title: 'Add Custom Model',
    machineName: 'Model Name',
    placeholder: 'Enter a model name within 15 characters',
    platSize: 'Platform Size',
    resolution: 'Resolution',
    addMachinePic: 'Add Model Image',
    confirm: 'Confirm',
    msg1: 'Custom model name cannot be empty',
    msg2: ' X-axis cannot be empty',
    msg3: 'Y-axis cannot be empty',
    msg4: 'Z-axis cannot be empty',
    msg5: 'Horizontal resolution cannot be empty',
    msg6: 'Vertical resolution cannot be empty',
    msg7: 'Please add model image',
    picSizeLimit: 'The size of the uploaded image cannot exceed 5MB!',
    updateSuc: 'Update successful',
    updateFail: 'Update failed',
    truPicFormat: 'Please upload the correct image format',
    delSuc: 'Deletion successful',
    delFail: 'Deletion failed',
    addSuc: 'Addition successful',
    addFail: 'Addition failed',
  },
  operateMachine: {
    title: 'Edit Custom Model',
    del: 'Delete',
    machineName: 'Model Name',
    placeholder: 'Enter a model name within 15 characters',
    platSize: 'Platform Size',
    resolution: 'Resolution',
    machinePic: 'Model Image',
    replacePic: 'Click to Replace Image',
    save: 'Save',
    isDelMachine: 'Delete this model?',
    cancel: 'Cancel',
    msg1: 'Custom model name cannot be empty',
    msg2: ' X-axis cannot be empty',
    msg3: 'Y-axis cannot be empty',
    msg4: 'Z-axis cannot be empty',
    msg5: 'Horizontal resolution cannot be empty',
    msg6: 'Vertical resolution cannot be empty',
    msg7: 'Please add model image',
    picSizeLimit: 'The size of the uploaded image cannot exceed 5MB!',
    updateSuc: 'Update successful',
    updateFail: 'Update failed',
    truPicFormat: 'Please upload the correct image format',
    delSuc: 'Deletion successful',
    delFail: 'Deletion failed',
    addSuc: 'Addition successful',
    addFail: 'Addition failed',
    saveSuc: 'Save successful',
  },
  selectConfigFile: {
    title: 'Select Configuration File',
    defaultConfig: 'Default Configuration',
    layThick: 'Layer Height',
    defineConfig: 'Custom Configuration',
    allPars: 'All Parameters',
    printSet: {
      title: 'Print Settings',
      resinType: {
        label: 'Resin Type',
        value: ''
      }, 
      layThick: {
        label: 'Layer Thickness',
        value: ''
      },
      singLayExpTime: 'Single Layer Exposure Time',
      bottomLayExpTime: 'Bottom Layer Exposure Time',
      expDelay: 'Exposure Delay',
      bottomLayExpLay: 'Bottom Layer Exposure Layers',
      bottomLayExpTranLay: 'Bottom Lay-Exposure Transition Layers',
      liftHeight1: 'Lift Height 1',
      lifgSpeed1: ' Lift Speed 1',
      liftHeight2: 'Lift Height 2',
      lifgSpeed2: ' Lift Speed 2',
      lowerSpeed: 'Lowering Speed',
      zAxLiftAfterPrint: 'Z-Axis Lift After Print'
    },
    highSet: {
      title: 'Advanced Settings',
      innerDiaComp: 'Inner Diameter Compensation',
      outDiaComp: 'Outer Diameter Compensation',
      xAxisScaleComp: 'X-Axis Scale Compensation',
      yAxisScaleComp: 'Y-Axis Scale Compensation',
      zAxisScaleComp: 'Z-Axis Scale Compensation',
      antial: {
        label: 'Antialiasing',
        value: 'No Antialiasing at Level "1"'
      },
      autoSup: 'Automatic Supports',
      antDisAtLevel1: 'No Antialiasing at Level "1"',
      grayLevel: 'Grayscale Level',
      imgBlurPx: 'Image Blur Pixels',
      supDensity: 'Support Density',
      addBase: 'Add Base',
      open: 'Open',
      close: 'Close'
    },
    delSuc: 'Deletion Successful',
    delFail: 'Deletion Failed'
  },
  saveConfigDialog: {
    txt: 'Naming Configuration',
    placeholder: 'Enter a configuration name within 15 characters',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  saveChangeConfigDialog: {
    txt: 'Parameters have been modified. Do you want to save the changes?',
    cancel: 'Cancel',
    saveAs: 'Save As'
  },
  saveUpdateConfigDialog: {
    txt: 'Parameters have been modified. Do you want to save the changes?',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  addModel: {
    title: 'Add Model',
    placeholder: 'Enter Model Name',
    noSearchRes: 'No Search Results',
    loading: 'Loading',
    noMore: 'No More',
    selectPrintModel: 'Select Print Model',
    allSelect: 'Select All',
    confirmSelect: 'Confirm Selection'
  }
}
