<template>
  <div class="machineManage">
    <i class="el-icon-close" @click="closeDialog"></i>
    <h1 class="title" v-if="isDefaultMachine">{{ machineParame.name }}</h1>
    <h1 class="set-name" v-else>
      <el-input
        v-model="machineParame.name"
        @blur="inputBlur"
        id="ipt"
        :readonly="!isEditMachineName"
      >
        <i
          class="el-icon-edit el-input__icon"
          slot="suffix"
          @click="handleIconClick"
        >
        </i>
      </el-input>
      <div class="custom">自定义</div>
    </h1>
    <el-form
      ref="form"
      label-position="top"
      :model="machineParame"
      :rules="rules"
      label-width="80px"
    >
      <div class="addForm">
        <div class="left">
          <div class="printSize">
            <div class="title">平台尺寸</div>
            <el-form-item label="X轴/宽" prop="printSize.x">
              <el-input-number
                :controls="false"
                v-model="machineParame.printSize.x"
                :precision="2"
                :min="50"
                :max="500"
                :disabled="isDefaultMachine"
              ></el-input-number>
              <div class="mm">mm</div>
              <template v-if="!isDefaultMachine">
                <div class="add" @click="machineParame.printSize.x++"></div>
                <div class="sub" @click="machineParame.printSize.x--"></div>
              </template>
            </el-form-item>
            <el-form-item label="Y轴/长" prop="printSize.y">
              <el-input-number
                :controls="false"
                v-model="machineParame.printSize.y"
                :precision="2"
                :min="50"
                :max="500"
                :disabled="isDefaultMachine"
              ></el-input-number>
              <div class="mm">mm</div>
              <template v-if="!isDefaultMachine">
                <div class="add" @click="machineParame.printSize.y++"></div>
                <div class="sub" @click="machineParame.printSize.y--"></div>
              </template>
            </el-form-item>
            <el-form-item label="Z轴/高" prop="printSize.z">
              <el-input-number
                :controls="false"
                v-model="machineParame.printSize.z"
                :precision="2"
                :min="50"
                :max="500"
                :disabled="isDefaultMachine"
              ></el-input-number>
              <div class="mm">mm</div>
              <template v-if="!isDefaultMachine">
                <div class="add" @click="machineParame.printSize.z++"></div>
                <div class="sub" @click="machineParame.printSize.z--"></div>
              </template>
            </el-form-item>
          </div>
          <div class="distinguishability">
            <div class="title">分辨率</div>
            <el-form-item label="水平分辨率" prop="distinguishability.horz">
              <el-input-number
                :controls="false"
                v-model="machineParame.distinguishability.horz"
                :precision="2"
                :min="0"
                :disabled="isDefaultMachine"
              ></el-input-number>
              <div class="px">px</div>
              <template v-if="!isDefaultMachine">
                <div
                  class="add"
                  @click="machineParame.distinguishability.horz++"
                ></div>
                <div
                  class="sub"
                  @click="machineParame.distinguishability.horz--"
                ></div>
              </template>
            </el-form-item>
            <el-form-item label="垂直分辨率" prop="distinguishability.vert">
              <el-input-number
                :controls="false"
                v-model="machineParame.distinguishability.vert"
                :precision="2"
                :min="0"
                :disabled="isDefaultMachine"
              ></el-input-number>
              <div class="px">px</div>
              <template v-if="!isDefaultMachine">
                <div
                  class="add"
                  @click="machineParame.distinguishability.vert++"
                ></div>
                <div
                  class="sub"
                  @click="machineParame.distinguishability.vert--"
                ></div>
              </template>
            </el-form-item>
          </div>
        </div>
        <div class="right">
          <el-form-item label="图示" prop="imageUrl">
            <div class="pic">
              <img :src="machineParame.imageUrl" alt="" />
            </div>
            <div class="update" v-if="!isDefaultMachine">
              <input type="file" accept="image/*" ref="ipt" />
              <span @click="updatePic">更换图片</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="btns">
        <el-button @click="save">确定</el-button>
        <el-button @click="delMachine" class="del" v-if="!isDefaultMachine"
          >删除</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { delMachine, updatePrinterType, addPrinterPic } from "../api/getInfo";
export default {
  name: "ManageMachine",
  props: {
    machineParameters: Object,
  },
  data() {
    return {
      machineName: "",
      machineParame: {
        name: "",
        printSize: {
          x: 0,
          y: 50,
          z: 50,
        },
        distinguishability: {
          horz: 0,
          vert: 0,
        },
        imageUrl: "",
      },
      rules: {
        name: [{ required: true, message: "自定义机型名称不能为空" }],
        "printSize.x": [{ required: true, message: "X轴不能为空" }],
        "printSize.y": [{ required: true, message: "Y轴不能为空" }],
        "printSize.z": [{ required: true, message: "Z轴不能为空" }],
        "distinguishability.horz": [
          { required: true, message: "水平分辨率不能为空" },
        ],
        "distinguishability.vert": [
          { required: true, message: "垂直分辨率不能为空" },
        ],
        imageUrl: [{ required: true, message: "请添加机型图片" }],
      },
      isDefaultMachine: true,
      isEditMachineName: false,
      pic: null,
    };
  },

  methods: {
    inputBlur() {
      document.querySelector("#ipt").style = "border-bottom: none";
      console.log("blur");
      if (!this.machineParame.name) {
        this.machineParame.name = this.machineName;
      }
    },
    closeDialog() {
      this.$emit("closeManageMachineDialog");
    },
    handleAvatarSuccess(res, file) {
      this.machineParame.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.machineParame.imageUrl);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          console.log("manage");
          //判断机型是否默认
          if (this.isDefaultMachine) {
            this.$emit("closeManageMachineDialog");
          }
          // 上传参数并关闭dialog
          else {
            //是否更新了图片
            if (this.pic) {
              const formData = new FormData();
              formData.append("file", this.pic);
              console.log('更改图片',this.pic);
              const res = await addPrinterPic(formData);
              //将返回的url更新然后重新上传更新打印机数据
              this.machineParame.imageUrl = res.data.url;
            }
            //上传更新的数据
            const res2 = await updatePrinterType({
              id: this.machineParameters.id,
              xAxisSize: this.machineParame.printSize.x,
              yAxisSize: this.machineParame.printSize.y,
              zAxisSize: this.machineParame.printSize.z,
              horizontalResolution: this.machineParame.distinguishability.horz,
              modelNumber: this.machineParame.name,
              name: this.machineParame.name,
              verticalResolution: this.machineParame.distinguishability.vert,
              picFileUrl: this.machineParame.imageUrl,
            });
            if (res2.code === 200) {
              this.$message({
                message: "更新成功",
                type: "success",
              });
              this.closeDialog()
            } else {
              this.$message.error('更新失败');
            }
          }

          /* this.machineParame = {
            name: "",
            printSize: {
              x: 0.0,
              y: 0,
              z: 0,
            },
            distinguishability: {
              horz: 0,
              vert: 0,
            },
            imageUrl: "",
          }; */
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleIconClick() {
      this.isEditMachineName = true;
      const ipt = document.querySelector("#ipt");
      ipt.style = "border-bottom: 1px solid #ccc;";
      ipt.focus();
    },
    updatePic() {
      this.$refs.ipt.click();
      this.$refs.ipt.addEventListener("change", (e) => {
        let pic = e.target.files[0];
        this.pic = pic;
        this.machineParame.imageUrl = URL.createObjectURL(pic);
      });
    },
    async delMachine() {
      const res = await delMachine(this.machineParameters.id);
      console.log("resDel", res);
      if (res.code === 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.$emit("closeManageMachineDialog", true);
      } else {
        this.$message.error("删除失败");
      }
    },
  },
  mounted() {
    // this.machineParame = this.machineParameters;
    console.log("par", this.machineParameters);
    this.isDefaultMachine =
      this.machineParameters.isDefault === 1 ? true : false;
    this.machineParame.printSize.x = this.machineParameters.xaxisSize;
    this.machineParame.printSize.y = this.machineParameters.yaxisSize;
    this.machineParame.printSize.z = this.machineParameters.zaxisSize;
    this.machineParame.distinguishability.horz =
      this.machineParameters.horizontalResolution;
    this.machineParame.distinguishability.vert =
      this.machineParameters.verticalResolution;
    this.machineParame.imageUrl = this.machineParameters.picFileUrl;
    this.machineParame.name = this.machineParameters.name;
    this.machineName = this.machineParameters.name;
  },
};
</script>

<style lang="less" scoped>
</style>